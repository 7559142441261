import { all } from 'redux-saga/effects';

import { watchAuth } from '@common/redux/auth/sagas';
import { watchCommons } from '@common/redux/common/sagas';
import { watchProfiles } from '@common/redux/profile/sagas';
import { watchLocations } from "@redux/location/sagas";
import { watchRoot } from "@redux/root/sagas";
import { watchCareerAdvisor }  from '@common/redux/careerAdvisor/sagas';
import { watchUploadFiles } from '@common/redux/upload/sagas';


export default function* rootSaga(): Generator {
  yield all([
    watchLocations(),
    watchProfiles(),
    watchAuth(),
    watchCommons(),
    watchRoot(),
    watchCareerAdvisor(),
    watchUploadFiles()
  ]);
}

