import { JsonProperty } from "json-object-mapper";

export interface mediaUrls{
  male: string;
  female: string;
}
interface IGeneralDescription {
  text: string;
  mediaURLs: mediaUrls;
}

interface IDayInTheLife {
  text: string;
}

interface IReason {
  reason: string;
}

interface IPrepareForRole {
  educationVsDegreeHeading: string;
  educationVsDegree: string;
  trainingNeeded: string;
  priorWorkExperience: string;
}

interface IAptitudeRating {
  attribute: string;
  score: string;
  reason: string;
  mediaURL: string;
}

interface IInterestRating {
  attribute: string;
  score: string;
  reason: string;
  mediaURL: string;
}

interface IValueRating {
  attribute: string;
  score: string;
  reason: string;
  mediaURL: string;
}

export interface IGeographicJobDetail {
  geographicOption: string;
  jobAvailability: string;
  estimatedSalaryRange: string;
}

interface IJobRole {
  title: string;
  years: string;
}

export interface ICareerPathway {
  pathwayTitle: string;
  description: string;
  jobRoles: IJobRole[];
}

interface IEmployerProfile {
  geographicOption: string;
  profiles: string;
}

interface IEmployer {
  name: string;
  description: string;
  website: string;
}

interface IEmployers {
  wellKnownEmployers: IEmployer[];
  employerProfiles: IEmployerProfile[];
}

export interface IJobProfile {
  dayInTheLife: IDayInTheLife;
  generalDescription: IGeneralDescription;
  reasonsLiked: IReason[];
  reasonsDisliked: IReason[];
  prepareForRole: IPrepareForRole;
}

export class JobProfileModel {
  @JsonProperty({ name: "jobProfile", required: false })
  private jobProfile: IJobProfile = {
    dayInTheLife: { text: "" },
    generalDescription: { text: "", mediaURLs: {
      male: "",
      female: ""
    }},
    reasonsLiked: [],
    reasonsDisliked: [],
    prepareForRole: {
      educationVsDegreeHeading: "",
      educationVsDegree: "",
      trainingNeeded: "",
      priorWorkExperience: "",
    },
  };

  @JsonProperty({ name: "aptitudeRatings", required: false })
  private aptitudeRatings: IAptitudeRating[] = [];

  @JsonProperty({ name: "interestRatings", required: false })
  private interestRatings: IInterestRating[] = [];

  @JsonProperty({ name: "valueRatings", required: false })
  private valueRatings: IValueRating[] = [];

  @JsonProperty({ name: "geographicJobDetails", required: false })
  private geographicJobDetails: IGeographicJobDetail[] = [];

  @JsonProperty({ name: "careerPathways", required: false })
  private careerPathways: ICareerPathway[] = [];

  @JsonProperty({ name: "employers", required: false })
  private employers: IEmployers = {
    wellKnownEmployers: [],
    employerProfiles: [],
  };

  @JsonProperty({ name: "jobRole", required: false })
  private jobRole: string = "";

  @JsonProperty({ name: "jobLocation", required: false })
  private jobLocation: string = "";

  @JsonProperty({ name: "sector", required: false })
  private sector: string = "";

  @JsonProperty({ name: "subSector", required: false })
  private subSector: string = "";

  @JsonProperty({ name: "generatedBy", required: false })
  private generatedBy: string = "";

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted: boolean = false;

  @JsonProperty({ name: "geoSalaryRatings", required: false })
  private geoSalaryRatings: any[] = [];

  @JsonProperty({ name: "_id", required: false })
  private id: string = "";

  @JsonProperty({ name: "jobRoleKey", required: false })
  private jobRoleKey: string = "";

  @JsonProperty({ name: "isFavourited", required: false })
  public isFavourited: boolean = false;

  @JsonProperty({ name: "collegeCategory", required: false })
  private collegeCategory: string = "";


  public getJobProfile(): IJobProfile {
    return this.jobProfile;
  }

  public getAptitudeRatings(): IAptitudeRating[] {
    return this.aptitudeRatings;
  }

  public getInterestRatings(): IInterestRating[] {
    return this.interestRatings;
  }

  public getValueRatings(): IValueRating[] {
    return this.valueRatings;
  }

  public getGeographicJobDetails(): IGeographicJobDetail[] {
    return this.geographicJobDetails;
  }

  public getCareerPathways(): ICareerPathway[] {
    return this.careerPathways;
  }

  public getEmployers(): IEmployers {
    return this.employers;
  }

  public getJobRole(): string {
    return this.jobRole;
  }

  public getJobLocation(): string {
    return this.jobLocation;
  }

  public getSector(): string {
    return this.sector;
  }

  public getSubSector(): string {
    return this.subSector;
  }

  public getGeneratedBy(): string {
    return this.generatedBy;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getGeoSalaryRatings(): any[] {
    return this.geoSalaryRatings;
  }

  public getId(): string {
    return this.id;
  }

  public getJobRoleKey(): string {
    return this.jobRoleKey;
  }

  public getIsFavourited(): boolean {
    return this.isFavourited;
  }

  public getCollegeCategory(): string {
    return this.collegeCategory;
  }
}



