import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { getErrorCodes } from '@utils/ErrorMessageUtils';
import { getLoggedInUserId, getUserUuid } from '@utils/UserUtils';

import { ProfileRepository } from '@repositories/ProfileRepository';
import { fetchLanguages, fetchProductTours, IFetchProductTour} from '@redux/common/actions';
import { fetchProductTourSaga } from '@redux/common/sagas';
import { fetchProfileDetailsSuccess } from '@redux/profile/actions';
import { fetchAppDataFailed, fetchAppDataSuccess, rootActions } from '@redux/root/actions';
import { logoutAction } from '@redux/auth/actions';
import { removeUserData } from '@redux/auth/sagas';
import { ProfileModel } from '@models/Profile';

import { userStatus } from '@constants/config';
import {
  setBetaUser
}from '../auth/actions';


export function* fetchAppDataSaga() {
    try {
        const userId = getLoggedInUserId();
        if (userId) {
            const response = yield call(ProfileRepository.fetchProfilesByUserIds, [userId]);
            const profiles: ProfileModel = response[0];
            if (profiles?.getUserStatus() === userStatus.DELETED) {
                yield call(removeUserData);
                yield put(logoutAction(getUserUuid()));
            };
            if (profiles.isBetaUser && (profiles.isStudent() || profiles.isFaculty())) {
                yield put(setBetaUser(true));
              
                const scope = profiles.isStudent() ? 'student' : profiles.isFaculty() ? 'faculty' : '';
                
                if (scope.length > 0) {
                  const apiParams: IFetchProductTour = {
                    products: 'CAREERS',
                    scopes: scope,
                    keys: 'product-tours',
                  };
                  yield call(fetchProductTourSaga, fetchProductTours(apiParams));
                }
              }
              
            yield put(fetchProfileDetailsSuccess(profiles));
            // This we can use later
            // yield put(fetchLanguages());
            yield put(fetchAppDataSuccess());
        }
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchAppDataFailed(error));
    }
}

export function* watchRoot(): Generator<ForkEffect> {
    yield takeLatest(rootActions.FETCH_APP_DATA, fetchAppDataSaga);
}
