import './wf_basic_components.less';

import * as WFText from '@components/OldText';
import * as WFButton from '@components/Button';
import * as WFInput from '@components/Input';
import Divider from '@components/Divider';
import LabelCapDivider from '@components/Divider/LabelCapDivider';
import MoreIcon from '@components/MoreIcon';
import Spinner from '@components/Spinner';
import * as WFImage from '@components/Image';
import * as Select from '@components/Select';
import * as WFMedia from '@components/Media';
import MarkDown from '@components/MarkDown';
import * as WFFooter from '@components/Footer';
import * as LayoutContainers from '@components/LayoutContainers';
import GobackArrow from './molecules/GoBack';
import DatePicker from './DatePicker';
import MultiSelectDropDown from './MultiSelectDropDown';

export const WFNormalInput = WFInput.NormalInput;
export const WFPasswordInput = WFInput.PasswordInput;
export const WFSearchTextInput = WFInput.SearchTextInput;
export const WFTextAreaInput = WFInput.TextAreaInput;

export const WFDivider = Divider;
export const WFLabelCapDivider = LabelCapDivider;
export const WFMoreIcon = MoreIcon;
export const WFSpinner = Spinner;
export const WFDatePicker = DatePicker;

export const WFImg = WFImage.Img;
export const WFSelect = Select.Select;

export const WFPrimaryButton = WFButton.PrimaryButton;
export const WFLinkButtonLoader = WFButton.LinkButtonLoader;
export const WFDefaultButton = WFButton.DefaultButton;
export const WFRoundedButton = WFButton.RoundedButton;
export const WFLinkButton = WFButton.LinkButton;
export const WFOutlineButton = WFButton.OutlineButton;
export const WFBorderedLabelButton = WFButton.BorderedLabelButton;

export const WFH1 = WFText.H1;
export const WFH2 = WFText.H2;
export const WFH3 = WFText.H3;
export const WFH4 = WFText.H4;
export const WFP = WFText.P;
export const WFTextNormal = WFText.TextNormal;
export const WFTextDanger = WFText.TextDanger;
export const WFTextWarning = WFText.TextWarning;
export const WFCardTitle = WFText.CardTitle;

export const WFVideoPlayer = WFMedia.VideoPlayer;

export const WFMarkdown = MarkDown;

export const WFFormFooter = WFFooter.FormFooter;

export const WFContentWrapper = LayoutContainers.ContentWrapper;
export const WFProfilePic = WFImage.ProfilePic;

export const WFGobackArrow = GobackArrow;

export const WFMultiSelectDropDown = MultiSelectDropDown;


