import { Language } from "@common/domain/models/Language";
import { LanguageName } from "@common/domain/models/LanguageName";

import { commonActions, fetchLanguageJson } from "./actions";
import { ITag } from "./interface";

export interface ICommonState {
  languages: Language[];
  tags: ITag[],
  tagsLanguages: ITag[],
  tagsMap: {},
  languageNames: LanguageName[],
  tagsInterviewPrepLanguages: ITag[],
  assessmentCompetencyList: {},
  productTours: string[],
  sectorList: [],
  degreeList: [],
  loaders: {
    fetchLanguages: boolean;
    tags: boolean;
    tagsLanguages: boolean;
    languageNames: boolean;
    tagsInterviewPrepLanguages: boolean;
    assessmentCompetencyList: boolean;
    fetchProductTours: boolean;
    sectorList: boolean;
    degreeList: boolean;
    fetchLanguageJson: boolean;
  };
  errors: {
    fetchLanguages: string;
    tags: string;
    tagsLanguages: string;
    languageNames: string;
    tagsInterviewPrepLanguages: string;
    assessmentCompetencyList: string;
    fetchProductTours: string;
    sectorList: string;
    degreeList: string;
    fetchLanguageJson: string;
  };
}

export const initialState: ICommonState = {
  languages: [],
  tags: [],
  tagsMap: {},
  tagsLanguages: [],
  languageNames: [],
  tagsInterviewPrepLanguages: [],
  assessmentCompetencyList: {},
  productTours: [],
  sectorList: [],
  degreeList: [],
  loaders: {
    fetchLanguages: false,
    tags: false,
    tagsLanguages: false,
    languageNames: false,
    tagsInterviewPrepLanguages: false,
    assessmentCompetencyList: false,
    fetchProductTours: false,
    sectorList: false,
    degreeList: false,
    fetchLanguageJson: false
  },
  errors: {
    fetchLanguages: "",
    tags: "",
    tagsLanguages: "",
    languageNames: "",
    tagsInterviewPrepLanguages: "",
    assessmentCompetencyList: "",
    fetchProductTours: "",
    sectorList: "",
    degreeList: "",
    fetchLanguageJson: ""
  },
};

export const commonReducer = (
  state: ICommonState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case commonActions.FETCH_LANGUAGES:
      return {
        ...state,
        languages: [],
        loaders: { ...state.loaders, fetchLanguages: true },
        errors: { ...state.errors, fetchLanguages: "" },
      };
    case commonActions.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload,
        loaders: { ...state.loaders, fetchLanguages: false },
      };
    case commonActions.FETCH_LANGUAGES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLanguages: false },
        errors: { ...state.errors, fetchLanguages: action.payload },
      };

    case commonActions.FETCH_TAGS:
      return {
        ...state,
        loaders: { ...state.loaders, tags: true },
      };
    case commonActions.FETCH_TAGS_SUCCESS:
      createTagMap(action.payload, state.tagsMap);
      return {
        ...state,
        tags: action.payload,
        loaders: { ...state.loaders, tags: false },
        errors: { ...state.errors, tags: '' },
      };
    case commonActions.FETCH_TAGS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, tags: false },
        errors: { ...state.errors, tags: action.payload },
      };
    case commonActions.FETCH_TAGS_LANGUAGES:
      return {
        ...state,
        loaders: { ...state.loaders, tagsLanguages: true },
      };
    case commonActions.FETCH_TAGS_LANGUAGES_SUCCESS:
      return {
        ...state,
        tagsLanguages: action.payload,
        loaders: { ...state.loaders, tagsLanguages: false },
        errors: { ...state.errors, tagsLanguages: '' },
      };
    case commonActions.FETCH_TAGS_LANGUAGES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, tagsLanguages: false },
        errors: { ...state.errors, tagsLanguages: action.payload },
      };

    case commonActions.FETCH_LANGUAGES_NAMES:
      return {
        ...state,
        languageNames: [],
        loaders: { ...state.loaders, languageNames: true },
        errors: { ...state.errors, languageNames: "" },
      };
    case commonActions.FETCH_LANGUAGES_NAMES_SUCCESS:
      return {
        ...state,
        languageNames: action.payload,
        loaders: { ...state.loaders, languageNames: false },
      };
    case commonActions.FETCH_LANGUAGES_NAMES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, languageNames: false },
        errors: { ...state.errors, languageNames: action.payload },
      };
    case commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES:
      return {
        ...state,
        loaders: { ...state.loaders, tagsInterviewPrepLanguages: true },
      };
    case commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_SUCCESS:
      return {
        ...state,
        tagsInterviewPrepLanguages: action.payload,
        loaders: { ...state.loaders, tagsInterviewPrepLanguages: false },
        errors: { ...state.errors, tagsInterviewPrepLanguages: '' },
      };
    case commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, tagsInterviewPrepLanguages: false },
        errors: { ...state.errors, tagsInterviewPrepLanguages: action.payload },
      };
    case commonActions.FETCH_ASSESSMENT_COMPETENCYLIST:
      return {
        ...state,
        loaders: { ...state.loaders, assessmentCompetencyList: true }
      }
    case commonActions.FETCH_ASSESSMENT_COMPETENCYLIST_SUCCESS:
      return {
        ...state,
        assessmentCompetencyList: action.payload,
        loaders: { ...state.loaders, assessmentCompetencyList: false }
      }
    case commonActions.FETCH_ASSESSMENT_COMPETENCYLIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, assessmentCompetencyList: false },
        errors: { ...state.errors, assessmentCompetencyList: action.payload }
      }

    case commonActions.FETCH_PRODUCT_TOURS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchProductTours: true }
      }
    case commonActions.FETCH_PRODUCT_TOURS_SUCCESS:
      return {
        ...state,
        productTours: action.payload,
        loaders: { ...state.loaders, fetchProductTours: false }
      }
    case commonActions.FETCH_PRODUCT_TOURS_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchProductTours: false },
        errors: { ...state.errors, fetchProductTours: action.payload }
      }
    case commonActions.FETCH_SECTOR_LIST:
      return {
        ...state,
        loaders: { ...state.loaders, sectorList: true }
      }
    case commonActions.FETCH_SECTOR_LIST_SUCCESS:
      return {
        ...state,
        sectorList: action.payload,
        loaders: { ...state.loaders, sectorList: false }
      }
    case commonActions.FETCH_SECTOR_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, sectorList: false },
        errors: { ...state.errors, sectorList: action.payload }
      }
    case commonActions.FETCH_DEGREE_LIST:
      return {
        ...state,
        loaders: { ...state.loaders, degreeList: true }
      }
    case commonActions.FETCH_DEGREE_LIST_SUCCESS:
      return {
        ...state,
        degreeList: action.payload,
        loaders: { ...state.loaders, degreeList: false }
      }
    case commonActions.FETCH_DEGREE_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, degreeList: false },
        errors: { ...state.errors, degreeList: action.payload }
      }

    case commonActions.FETCH_LANGUAGE_JSON:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLanguageJson: true },
        errors: { ...state.errors, fetchLanguageJson: "" },
      };
    case commonActions.FETCH_LANGUAGE_JSON_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLanguageJson: false },
      };
    case commonActions.FETCH_LANGUAGE_JSON_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, fetchLanguageJson: false },
        errors: { ...state.errors, fetchLanguageJson: action.payload },
      };  
    default:
      return state;
  }
};

const createTagMap = (tags: ITag[], existingMap: {}) => {
  tags.forEach((tag: ITag) => {
    if (!existingMap[tag?.groupName] && tag?.isGrouped) {
      existingMap[tag?.groupName] = tag.groupValues;
    }
  });
};