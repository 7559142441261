
import { IFluxStandardAction } from "@store/webInterfaces";
import { JobProfileModel } from "@common/domain/models/JobProfileModel";
import { PssciveCourse } from "@common/domain/models/PssciveCourse";

const actionTypePrefix = "CAREERADVISOR/";

export const careerAdvisorActions = {
  FETCH_JOB_ROLES: `${actionTypePrefix}FETCH_JOB_ROLES`,
  FETCH_JOB_ROLES_SUCCESS: `${actionTypePrefix}FETCH_JOB_ROLES_SUCCESS`,
  FETCH_JOB_ROLES_FAIL: `${actionTypePrefix}FETCH_JOB_ROLES_FAIL`,

  UPDATE_FILTER_VALUES: `${actionTypePrefix}UPDATE_FILTER_VALUES`,

  FETCH_JOBROLE_BY_ID: `${actionTypePrefix}FETCH_JOBROLE_BY_ID`,
  FETCH_JOBROLE_BY_ID_SUCCESS: `${actionTypePrefix}FETCH_JOBROLE_BY_ID_SUCCESS`,
  FETCH_JOBROLE_BY_ID_FAIL: `${actionTypePrefix}FETCH_JOBROLE_BY_ID_FAIL`,

  ADD_OR_REMOVE_FAVOURITE: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE`,
  ADD_OR_REMOVE_FAVOURITE_SUCCESS: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE_SUCCESS`,
  ADD_OR_REMOVE_FAVOURITE_FAIL: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE_FAIL`,

  GET_FAVOURITE_KEYS: `${actionTypePrefix}GET_FAVOURITE_KEYS`,
  GET_FAVOURITE_KEYS_SUCCESS: `${actionTypePrefix}GET_FAVOURITE_KEYS_SUCCESS`,
  GET_FAVOURITE_KEYS_FAIL: `${actionTypePrefix}GET_FAVOURITE_KEYS_FAIL`,

  UPDATE_CAREER_PREFERENCE: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE`,
  UPDATE_CAREER_PREFERENCE_SUCCESS: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE_SUCCESS`,
  UPDATE_CAREER_PREFERENCE_FAIL: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE_FAIL`,

  FETCH_CAREER_PREFERENCE: `${actionTypePrefix}FETCH_CAREER_PREFERENCE`,
  FETCH_CAREER_PREFERENCE_SUCCESS: `${actionTypePrefix}FETCH_CAREER_PREFERENCE_SUCCESS`,
  FETCH_CAREER_PREFERENCE_FAIL: `${actionTypePrefix}FETCH_CAREER_PREFERENCE_FAIL`,

  FETCH_ATTRIBUTE_GROUPS: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS`,
  FETCH_ATTRIBUTE_GROUPS_SUCCESS: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS_SUCCESS`,
  FETCH_ATTRIBUTE_GROUPS_FAIL: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS_FAIL`,

  SUBMIT_ATTRIBUTE_USER_RESPONSE: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE`,
  SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS`,
  SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL`,

  DELETE_ATTRIBUTE_USER_RESPONSE: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE`,
  DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS`,
  DELETE_ATTRIBUTE_USER_RESPONSE_FAIL: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE_FAIL`,

  GET_QUESTIONNAIRE_RESPONSE: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE`,
  GET_QUESTIONNAIRE_RESPONSE_SUCCESS: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE_SUCCESS`,
  GET_QUESTIONNAIRE_RESPONSE_FAIL: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE_FAIL`,

  GET_PSSCIVE_COURSES: `${actionTypePrefix}GET_PSSCIVE_COURSES`,
  GET_PSSCIVE_COURSES_SUCCESS: `${actionTypePrefix}GET_PSSCIVE_COURSES_SUCCESS`,
  GET_PSSCIVE_COURSES_FAIL: `${actionTypePrefix}GET_PSSCIVE_COURSES_FAIL`,
  
  GET_JOB_PROFILE_BY_USERID: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID`,
  GET_JOB_PROFILE_BY_USERID_SUCCESS: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID_SUCCESS`,
  GET_JOB_PROFILE_BY_USERID_FAIL: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID_FAIL`,

  GET_ATTRIBUTES_RESPONSES_BY_USERID: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID`,
  GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS`,
  GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL`,

  GET_USER_PREFERENCES_BY_USERID: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID`,
  GET_USER_PREFERENCES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID_SUCCESS`,
  GET_USER_PREFERENCES_BY_USERID_FAIL: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID_FAIL`,

  GET_FAVORITE_JOBROLES_BY_USERID: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID`,
  GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS`,
  GET_FAVORITE_JOBROLES_BY_USERID_FAIL: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID_FAIL`,

  GET_USER_DETAILS_BY_USERID: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID`,
  GET_USER_DETAILS_BY_USERID_SUCCESS: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID_SUCCESS`,
  GET_USER_DETAILS_BY_USERID_FAIL: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID_FAIL`,

}

export interface IJobRolesPayload {
  res?: any,
  page?: number,
  jobRoleKeys?: string[];
  nonPaginated?: boolean;
}

export interface IFavouritesPayload {
  jobRoleKeys: string[];
}

interface IAttributeScores {
  attributeName: string;
  attributeKey: string;
  userInputScore?: number;
  questionnaireScore?: number;

}

export interface ISubmitUserResponsePayload {
  attributeGroupName: string;
  attributeGroupKey: string;
  attributeScores: IAttributeScores[]
}

export interface IGetQuestionnaireResponse {
  questionnaireId: string;
  respondentId: string;
}

export interface IPssciveCoursePayload {
  searchTerm?: string;
  language?: string;
  searchOn?: string;
  page?: number;
  limit?: number;
}


export const fetchJobRoles = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES,
    payload
  }
}

export const fetchJobRolesSuccess = (jobProfiles: JobProfileModel[], total?: number, page?: number) => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES_SUCCESS,
    payload: {
      jobProfiles,
      total,
      page
    }
  }
}

export const fetchJobRolesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES_FAIL,
    payload: error,
  }
}

export const fetchJobDetailById = (jobRoleId: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID,
    payload: jobRoleId
  }
}

export const fetchJobDetailByIdSuccess = (jobRoleId: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID_SUCCESS,
    payload: jobRoleId
  }
}

export const fetchJobDetailByIdFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID_FAIL,
    payload: error
  }
}

export const updateCareerPreference = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE,
    payload
  }
}

export const updateCareerPreferenceSuccess = (payload) => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE_SUCCESS,
    payload
  }
}

export const updateCareerPreferenceFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE_FAIL,
    payload: error,
  }
}

export const fetchCareerPreference = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE,
    payload
  }
}

export const fetchCareerPreferenceSuccess = (payload) => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE_SUCCESS,
    payload
  }
}

export const fetchCareerPreferenceFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE_FAIL,
    payload: error,
  }
}

export const addOrRemoveFavourites = (payload: IFavouritesPayload) => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE,
    payload
  }
}

export const addOrRemoveFavouritesSuccess = (jobRoleKeys: string[]) => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_SUCCESS,
    payload: {
      jobRoleKeys
    }
  }
}

export const addOrRemoveFavouritesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_FAIL,
    payload: error,
  }
}

export const getFavouritesKeys = () => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS
  }
}

export const getFavouritesKeysSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS_SUCCESS,
    payload
  }
}

export const getFavouritesKeysFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS_FAIL,
    payload: error,
  }
}

export const fetchAttributeGroups = () => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS
  }
}

export const fetchAttributeGroupsSuccess = (payload) => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_SUCCESS,
    payload
  }
}

export const fetchAttributeGroupsFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_FAIL,
    payload: error,
  }
}

export const submitAttributeUserResponse = (payload: ISubmitUserResponsePayload) => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE,
    payload
  }
}

export const submitAttributeUserResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS,
    payload
  }
}

export const submitAttributeUserResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL,
    payload: error,
  }
}

export const updateFilterValues = (payload) => {
  return {
    type: careerAdvisorActions.UPDATE_FILTER_VALUES,
    payload: payload,
  };
};

export const deleteAttributeUserResponse = (payload: string) => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE,
    payload
  }
}

export const deleteAttributeUserResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS,
    payload
  }
}

export const deleteAttributeUserResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_FAIL,
    payload: error,
  }
}

export const getQuestionnaireResponse = (payload: IGetQuestionnaireResponse) => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE,
    payload
  }
}

export const getQuestionnaireResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_SUCCESS,
    payload
  }
}

export const getQuestionnaireResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_FAIL,
    payload: error,
  }
}

export const getPssciveCourses = (payload: IPssciveCoursePayload) => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES,
    payload
  }
}

export const getPssciveCoursesSuccess = (pssciveCourses: PssciveCourse[], total?: number, page?: number) => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES_SUCCESS,
    payload: {
      pssciveCourses,
      total,
      page
    }
  }
}

export const getPssciveCoursesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES_FAIL,
    payload: error,
  }
}

export const getJobProfileByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID,
    payload
  }
}

export const getJobProfileByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_SUCCESS,
    payload
  }
}

export const getJobProfileByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_FAIL,
    payload: error,
  }
}

export const getAttributesResponsesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID,
    payload
  }
}

export const getAttributesResponsesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS,
    payload
  }
}

export const getAttributesResponsesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getUserPreferencesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID,
    payload
  }
}

export const getUserPreferencesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_SUCCESS,
    payload
  }
}

export const getUserPreferencesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getFavoriteJobrolesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID,
    payload
  }
}

export const getFavoriteJobrolesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS,
    payload
  }
}

export const getFavoriteJobrolesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getUserDetailsByUserId= (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID,
    payload
  }
}

export const getUserDetailsByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID_SUCCESS,
    payload
  }
}

export const getUserDetailsByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID_FAIL,
    payload: error,
  }
}





