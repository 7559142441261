import { JsonProperty } from "json-object-mapper";

interface IAttributeLocation {
  country?: string;
};

export interface IAttributes {
  questionnaireId: string;
  attributeKey: string;
  userInputScore?: number;
  questionnaireScore?: number;
  mediaURL: string;
};

export class AttributeGroups {

  @JsonProperty({ name: "key", required: true })
  private key: string = "";

  @JsonProperty({ name: "version", required: false })
  private version: number = 0;

  @JsonProperty({ name: "location", required: false })
  private location: IAttributeLocation = {} as IAttributeLocation;

  @JsonProperty({ name: "languageCode", required: false })
  private languageCode: string = "";

  @JsonProperty({ name: "attributes", required: true })
  private attributes: IAttributes[] = [];

  @JsonProperty({ name: "deleted", required: true })
  private deleted: boolean = false;

  @JsonProperty({ name: "id", required: true })
  private id: string = "";

  public getKey() {
    return this.key;
  }

  public getVersion() {
    return this.version;
  }

  public getLocation() {
    return this.location;
  }

  public getLanguageCode() {
    return this.languageCode;
  }

  public getAttributes() {
    return this.attributes;
  }

  public getDeleted() {
    return this.deleted;
  }

  public getId() {
    return this.id;
  }

}

