import React, { useEffect, useState } from 'react';

import { CloseIcon, CloseModal,  SearchInputIcon } from '@assets/index';

import { WFImg, WFNormalInput } from '@components/index';

import { MINIMUM_SEARCH_CHAR_LIMIT } from '@constants/config';

import './styles.less';

interface IProps {
  searchKeyword: string;
  onSearchKeywordChange: (keyword: string) => void;
  onEnterPressed?: (e: any) => void;
  onCancelPressed?: () => void;
  isModalTextInput?: boolean;
  placeholder?: string;
  className?: string;
  isFocused?: boolean;
  delay?:number;
  charLength?:number;
}

const SearchTextInput = (props: IProps) => {
  const {
    searchKeyword,
    onSearchKeywordChange,
    onEnterPressed,
    placeholder,
    className,
    isFocused,
    delay=500,
    charLength=MINIMUM_SEARCH_CHAR_LIMIT
  } = props;

  let [updatedKeyword, setUpdatedKeyword] = useState<string>(searchKeyword);

  const onChange = e => {
    const keyword = e.target.value;
    setUpdatedKeyword(keyword);
  };

  const onPressCancel = () => {
    setUpdatedKeyword('');
    const { onCancelPressed } = props;
    onCancelPressed && onCancelPressed();
  };

  const onPressEnter = (e: any) => {
    const keyword: string = e.target.value;
    onEnterPressed && onEnterPressed(keyword);
  };

  useEffect(() => {
    var timer = setTimeout(() => {
        const updatedKeywordTrimmed = updatedKeyword.trim();
      if (updatedKeywordTrimmed.length >= charLength || updatedKeywordTrimmed.length === 0)
        onSearchKeywordChange && onSearchKeywordChange(updatedKeywordTrimmed);
    }, delay);

    return function() {
      clearTimeout(timer);
    };
  }, [updatedKeyword]);

  useEffect(() => {
    setUpdatedKeyword(searchKeyword);
  }, [searchKeyword]);


  const style = ['search_text_input', className].join(' ');

  return (
      <WFNormalInput
        placeholder={placeholder}
        suffix={
          (updatedKeyword.trim() !== '') &&
            <span onClick={onPressCancel}>
              <WFImg src={CloseIcon} className="close_modal_button" />
            </span>
        }
        prefix={<WFImg src={SearchInputIcon} className="search_icon" />}
        className={style}
        autoFocus={isFocused}
        type={'search'}
        value={updatedKeyword}
        onChange={onChange}
        onPressEnter={onPressEnter}
      />
  );
};


export default SearchTextInput;
