import { JsonProperty } from "json-object-mapper";

interface IAttributeScores {
    attributeName: string;
    attributeKey: string;
    questionnaireScore: number;
    userInputScore: number;
};

export class AttributeGroupResponse {

    @JsonProperty({ name: "attributeGroupName", required: true })
    private attributeGroupName: string = "";

    @JsonProperty({ name: "attributeGroupKey", required: true })
    private attributeGroupKey: string = "";

    @JsonProperty({ name: "attributeScores", required: true })
    private attributeScores: IAttributeScores[] = [];

    @JsonProperty({ name: "createdAt", required: false })
    private createdAt: string = "";

    @JsonProperty({ name: "deleted", required: false })
    private deleted: boolean = false;

    @JsonProperty({ name: "updatedAt", required: false })
    private updatedAt: string = "";

    @JsonProperty({ name: "id", required: false })
    private id: string = "";

    public getGroupName() {
        return this.attributeGroupName;
    }

    public getGroupKey() {
      return this.attributeGroupKey;
    }

    public getAttributeScores() {
        return this.attributeScores;
    }

    public getDeleted() {
        return this.deleted;
    }

    public getCreatedAt(){
      return this.createdAt;
    }

    public getUpdatedAt() {
      return this.updatedAt;
    }

    public getId(){
      return this.id;
    }

}

