import { initReactI18next } from 'react-i18next';

import { Logger } from '@utils/Logger';

import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  
};

const detectionOptions = {
  // order and from where course language should be detected
  order: ['path', 'subdomain', 'querystring', 'navigator', 'htmlTag'],

  lookupQuerystring: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

const i18nOptions = {
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  lng: 'en',
  whitelist: ['en', 'es', 'pt', 'en-US', 'hi', 'id', 'fil', 'kn', 'bn', 'or', 'mr'],
  debug: true,
  defaultNS: 'cou',
  contextSeparator: '-',
  pluralSeparator: '-',
  returnObjects: true,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}'
  },
  resources,
  detection: detectionOptions,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

class CourseI18nInstance {
  private i18n: any;

  private async init() {
    Logger.info('[CourseI18nInstance - init()] Creating i18n new instance');
    this.i18n = i18next.createInstance();
    this.i18n.use(LanguageDetector);
    this.i18n.use(initReactI18next);
    await this.i18n.init(i18nOptions, (err: any, t: any) => {
      if (err) {
        Logger.error(
          '[CourseI18nInstance - init()]',
          { error: 'unable to init i18n instance. err:' + err },
        );
      }
      Logger.info(
        '[CourseI18nInstance - init()]',
        { info: 'instance created successfully. Test:' + t('labelsAndTitles.HOME')},
      );
    });
  }

  public getI18n() {
    if (!this.i18n) {
      this.init();
    }
    return this.i18n;
  }
}

const courseI18nSingleton = new CourseI18nInstance();
const courseAdvantagei18n = courseI18nSingleton.getI18n();
export { courseAdvantagei18n as i18n };
