import { AzureTokenModel } from '@common/domain/models/AzureTokenModel';
import { LoginModel } from '@common/domain/models/Login';

import {
    ICheckUserExist,
    IEmailVerify,
    IForgotPassword,
    ILoginPayload,
    IMaintenanceErrorPayload,
    IResendOtpPayload,
    IResendVerifyMail,
    IResetPassword,
    ISocialMediaLogin,
    ISSOLoginPayload,
    IUserModel
} from '@redux/auth/interface';

import { IFluxStandardAction } from '@store/webInterfaces';

const actionTypePrefix = 'AUTH/';

export const authActions = {
    LOGIN: `${actionTypePrefix}LOGIN`,
    SSO_LOGIN: `${actionTypePrefix}SSO_LOGIN`,
    LOGIN_SUCCESS: `${actionTypePrefix}LOGIN_SUCCESS`,
    SOCIAL_MEDIA_LOGIN: `${actionTypePrefix}SOCIAL_MEDIA_LOGIN`,
    LOGIN_FAIL: `${actionTypePrefix}LOGIN_FAIL`,

    CHECK_USER_EXIST: `${actionTypePrefix}CHECK_USER_EXIST`,
    CHECK_USER_EXIST_SUCCESS: `${actionTypePrefix}CHECK_USER_EXIST_SUCCESS`,
    CHECK_USER_EXIST_FAIL: `${actionTypePrefix}CHECK_USER_EXIST_FAIL`,

    LOGOUT: `${actionTypePrefix}LOGOUT`,
    LOGOUT_SUCCESS: `${actionTypePrefix}LOGOUT_SUCCESS`,
    LOGOUT_FAIL: `${actionTypePrefix}LOGOUT_FAIL`,
    SET_AUTHENTICATED_FLAG: `${actionTypePrefix}SET_AUTHENTICATED_FLAG`,

    REGISTER: `${actionTypePrefix}REGISTER`,
    REGISTER_SUCCESS: `${actionTypePrefix}REGISTER_SUCCESS`,
    REGISTER_FAIL: `${actionTypePrefix}REGISTER_FAIL`,

    RESEND_VERIFY_MAIL: `${actionTypePrefix}RESEND_VERIFY_MAIL`,
    RESEND_VERIFY_MAIL_SUCCESS: `${actionTypePrefix}RESEND_VERIFY_MAIL_SUCCESS`,
    RESEND_VERIFY_MAIL_FAIL: `${actionTypePrefix}RESEND_VERIFY_MAIL_FAIL`,

    FORGOT_PASSWORD: `${actionTypePrefix}FORGOT_PASSWORD`,
    FORGOT_PASSWORD_SUCCESS: `${actionTypePrefix}FORGOT_PASSWORD_SUCCESS`,
    FORGOT_PASSWORD_FAIL: `${actionTypePrefix}FORGOT_PASSWORD_FAIL`,

    CHANGE_PASSWORD: `${actionTypePrefix}CHANGE_PASSWORD`,
    CHANGE_PASSWORD_SUCCESS: `${actionTypePrefix}CHANGE_PASSWORD_SUCCESS`,
    CHANGE_PASSWORD_FAIL: `${actionTypePrefix}CHANGE_PASSWORD_FAIL`,

    CLEAR_USER: `${actionTypePrefix}CLEAR_USER`,

    SET_WEBVIEW: `${actionTypePrefix}SET_WEBVIEW`,

    RESET_FORGOT_PASSWORD_DATA: `${actionTypePrefix}RESET_FORGOT_PASSWORD_DATA`,

    RESET_PASSWORD: `${actionTypePrefix}RESET_PASSWORD`,
    RESET_PASSWORD_SUCCESS: `${actionTypePrefix}RESET_PASSWORD_SUCCESS`,
    RESET_PASSWORD_FAIL: `${actionTypePrefix}RESET_PASSWORD_FAIL`,

    SET_FIRST_TIME_USER: `${actionTypePrefix}SET_FIRST_TIME_USER`,
    SET_MAINTENANCE_FLAG: `${actionTypePrefix}SET_MAINTENANCE_FLAG`,

    EMAIL_VERIFICATION: `${actionTypePrefix}EMAIL_VERIFICATION`,
    EMAIL_VERIFICATION_SUCCESS: `${actionTypePrefix}EMAIL_VERIFICATION_SUCCESS`,
    EMAIL_VERIFICATION_FAIL: `${actionTypePrefix}EMAIL_VERIFICATION_FAIL`,

    GET_NSDC_SSO_URL: `${actionTypePrefix}/GET_NSDC_SSO_URL`,
    GET_NSDC_SSO_URL_SUCCESS: `${actionTypePrefix}/GET_NSDC_SSO_URL_SUCCESS`,
    GET_NSDC_SSO_URL_FAIL: `${actionTypePrefix}/GET_NSDC_SSO_URL_FAIL`,

    AUTHORISE_SSO_USER: `${actionTypePrefix}/AUTHORISE_SSO_USER`,
    AUTHORISE_SSO_USER_SUCCESS: `${actionTypePrefix}/AUTHORISE_SSO_USER_SUCCESS`,
    AUTHORISE_SSO_USER_FAIL: `${actionTypePrefix}/AUTHORISE_SSO_USER_FAIL`,
    
    SET_BETA_USER: `${actionTypePrefix}SET_BETA_USER`,

    RESEND_OTP: `${actionTypePrefix}RESEND_OTP`,
    RESEND_OTP_SUCCESS: `${actionTypePrefix}RESEND_OTP_SUCCESS`,
    RESEND_OTP_FAIL: `${actionTypePrefix}RESEND_OTP_FAIL`

};

export const fetchAzureTokenActions = {
    FETCH_AZURE_TOKEN: `${actionTypePrefix}/FETCH_AZURE_TOKEN`,
    FETCH_AZURE_TOKEN_SUCCESS: `${actionTypePrefix}/FETCH_AZURE_TOKEN_SUCCESS`,
    FETCH_AZURE_TOKEN_FAIL: `${actionTypePrefix}/FETCH_AZURE_TOKEN_FAIL`
};


export interface ISetBetaUserAction {
  type: string;
  payload: boolean;
}


export const setFirstTimeUserAction = (isFirstTimeUser: boolean) => ({
    type: authActions.SET_FIRST_TIME_USER,
    payload: isFirstTimeUser,
});

export const setAuthenticatedFlag = (isAuthenticated: boolean): { type: string, payload: boolean } => ({
    type: authActions.SET_AUTHENTICATED_FLAG,
    payload: isAuthenticated,
});

export const login = (payload: ILoginPayload): IFluxStandardAction<ILoginPayload> => ({
    type: authActions.LOGIN,
    payload
});

export const ssoLogin = (payload: ISSOLoginPayload): IFluxStandardAction<ISSOLoginPayload> => ({
    type: authActions.SSO_LOGIN,
    payload
});

export const loginSuccess = (payload: LoginModel): IFluxStandardAction<LoginModel> => ({
    type: authActions.LOGIN_SUCCESS,
    payload
});

export const loginFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.LOGIN_FAIL,
    payload: error
});

export const checkUserExist = (payload: ICheckUserExist): IFluxStandardAction<ICheckUserExist> => ({
    type: authActions.CHECK_USER_EXIST,
    payload
});

export const checkUserExistSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: authActions.CHECK_USER_EXIST_SUCCESS,
    payload
});

export const checkUserExistFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.CHECK_USER_EXIST_FAIL,
    payload: error
});

export const register = (payload: ILoginPayload): IFluxStandardAction<ILoginPayload> => ({
    type: authActions.REGISTER,
    payload
});

export const registerSuccess = (payload: {}): IFluxStandardAction<{}> => ({
    type: authActions.REGISTER_SUCCESS,
    payload
});

export const registerFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.REGISTER_FAIL,
    payload: error
});

export const forgotPassword = (payload: IForgotPassword): IFluxStandardAction<IForgotPassword> => ({
    type: authActions.FORGOT_PASSWORD,
    payload
});

export const forgotPasswordSuccess = (payload: {}): IFluxStandardAction<{}> => ({
    type: authActions.FORGOT_PASSWORD_SUCCESS,
    payload
});

export const forgotPasswordFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.FORGOT_PASSWORD_FAIL,
    payload: error
});

export const resendVerifyMail = (payload: ILoginPayload): IFluxStandardAction<ILoginPayload> => ({
    type: authActions.RESEND_VERIFY_MAIL,
    payload
});

export const resendVerifyMailSuccess = (payload: IResendVerifyMail): IFluxStandardAction<IResendVerifyMail> => ({
    type: authActions.RESEND_VERIFY_MAIL_SUCCESS,
    payload
});

export const resendVerifyMailFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.RESEND_VERIFY_MAIL_FAIL,
    payload: error,
});

export const logoutAction = (uuid: string) => ({
    type: authActions.LOGOUT,
    payload: uuid,
});

export const logoutSuccessAction = (): IFluxStandardAction => ({
    type: authActions.LOGOUT_SUCCESS,
});

export const logoutFailureAction = (error: {}): { type: string, payload: {} } => ({
    type: authActions.LOGOUT_FAIL,
    payload: error,
});

export const changePassword = (payload: ILoginPayload): IFluxStandardAction<ILoginPayload> => ({
    type: authActions.CHANGE_PASSWORD,
    payload
});

export const changePasswordSuccess = (): IFluxStandardAction<boolean> => ({
    type: authActions.CHANGE_PASSWORD_SUCCESS,
    payload: true
});

export const changePasswordFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.CHANGE_PASSWORD_FAIL,
    payload: error
});

export const clearUser = (): IFluxStandardAction<string> => ({
    type: authActions.CLEAR_USER,
});

export const setWebView = () => ({
    type: authActions.SET_WEBVIEW,
});

export const resetForgotPasswordData = (): IFluxStandardAction<string> => ({
    type: authActions.RESET_FORGOT_PASSWORD_DATA,
});

export const resetPassword = (payload: IResetPassword): IFluxStandardAction<IResetPassword> => ({
    type: authActions.RESET_PASSWORD,
    payload
});

export const resetPasswordSuccess = (): IFluxStandardAction<boolean> => ({
    type: authActions.RESET_PASSWORD_SUCCESS,
    payload: true
});

export const resetPasswordFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.RESET_PASSWORD_FAIL,
    payload: error
});

// email verification
export const emailVerification = (payload: IEmailVerify): IFluxStandardAction<IEmailVerify> => ({
    type: authActions.EMAIL_VERIFICATION,
    payload
});

export const emailVerificationSuccess = (payload) => ({
    type: authActions.EMAIL_VERIFICATION_SUCCESS,
    payload
});

export const emailVerificationFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.EMAIL_VERIFICATION_FAIL,
    payload: error
});

export const superAdminSignIn = (userData: IUserModel): { type: string, payload: {} } => ({
    type: authActions.LOGIN,
    payload: { userData, fromSuperAdminSignIn: true },
});

// Social Media Login
export const socialMediaLogin = (payload: ISocialMediaLogin): IFluxStandardAction<ISocialMediaLogin> => ({
    type: authActions.SOCIAL_MEDIA_LOGIN,
    payload
});

export const setMaintenanceFlag = (payload: IMaintenanceErrorPayload) => {
    return {
        type: authActions.SET_MAINTENANCE_FLAG,
        payload
    }
}

//Nsdc Flow
export const getNsdcSsoUrl = (payload:string) => {
    return {
        type: authActions.GET_NSDC_SSO_URL,
        payload
    }
}

export const getNsdcSsoUrlSuccess = (data: any) => {
    return {
        type: authActions.GET_NSDC_SSO_URL_SUCCESS,
        payload: data
    }
}

export const getNsdcSsoUrlFail = (error: string) => {
    return {
        type: authActions.GET_NSDC_SSO_URL_FAIL,
        payload: error
    }
}

export const authoriseSsoUser = (payload) => {
    return {
        type: authActions.AUTHORISE_SSO_USER,
        payload
    }
}

export const authoriseSsoUserSuccess = (payload) => {
    return {
        type: authActions.AUTHORISE_SSO_USER_SUCCESS,
        payload
    }
}

export const authoriseSsoUserFail = (error) => {
    return {
        type: authActions.AUTHORISE_SSO_USER_FAIL,
        payload: error
    }
}


export const setBetaUser = (isElite: boolean): ISetBetaUserAction => ({
    type: authActions.SET_BETA_USER,
    payload: isElite,
});

export const resendOtp = (payload: IResendOtpPayload): IFluxStandardAction<IResendOtpPayload> => ({
    type: authActions.RESEND_OTP,
    payload
});

export const resendOtpSuccess = (payload) => ({
    type: authActions.RESEND_OTP_SUCCESS,
    payload
});

export const resendOtpFail = (error: string): IFluxStandardAction<string> => ({
    type: authActions.RESEND_OTP_FAIL,
    payload: error,
});

export const fetchAzureToken = () => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN
});

export const fetchAzureTokenSuccess = (azureToken: AzureTokenModel) => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN_SUCCESS,
    payload: azureToken,
});

export const fetchAzureTokenFail = (error: string) => ({
    type: fetchAzureTokenActions.FETCH_AZURE_TOKEN_FAIL,
    payload: error,
});

