import { platform } from "@constants/config";

// export const Cross = require('./cross.svg');
export const CloseModal = require('./Icons/CloseModal.svg');
export const SearchInputIcon = require('./Images/SearchInputIcon.svg');
export const ActiveUp = require('./Icons/ActiveUp.svg');
export const ActiveDown = require('./Icons/ActiveDown.svg');
export const File = require('./Icons/File.svg');
export const moreIcon = require('./Icons/More.svg');

export const venture_image = require('./Images/venture.png');
// export const Calendar = require("./Images/calendar.svg");
export const Copy = require('./Images/copy.svg');
export const Cross = require('./Images/cross.svg');
export const FallbackColor = require('./Images/e4e4e5.png');
// export const Ham = require("./Images/ham.svg");
// export const more_options = require("./Images/more-options.svg");
export const PopUpClose = require('./Images/popup-close.svg');
// export const profile_icon = require("./Images/profile_icon.svg");
// export const rectangle_icon = require("./Images/rectangle-icon.svg");
// export const WF_Color = require("./Images/WF-Color.svg");
// export const WF_Logo_Color = require("./Images/WF-Logo-Color.png");
// export const LoginBanner = require("./Images/login-banner-compressed.jpg");
export const LockIcon = require('./Icons/LockIcon.svg');
export const CheckIcon = require('./Icons/Check.svg');
export const TimeIcon = require('./Icons/Time.svg');
// export const ReplaceIcon = require("./Icons/Replace.svg");
// export const DoneIcon = require("./Icons/Done.svg");
export const ThreeDots = require('./Icons/threeDots.svg');
// export const Community = require("./Images/Community.svg");
export const Edit = require('./Icons/Edit.svg');
// export const IndustryType = require("./Images/next selected.svg");
export const Location = require('./Icons/Location.svg');
// export const Share = require("./Images/Share.svg");
export const Up = require('./Icons/Up.svg');
export const CommunityBordered = require('./Images/CommunityBordered.svg');
// export const LocationBordered = require("./Images/LocationBordered.svg");
// export const ShareBordered = require("./Images/ShareBordered.svg");
// export const Planner = require("./Icons/Planner.svg");
// export const Office = require("./Icons/Office.svg");
export const Planner = require("./Icons/Planner.svg");
export const Calender = require("./Icons/Calendar.svg");
export const Dashboard = require("./Icons/Dashboard.svg");
export const Event = require("./Icons/Event.svg");
export const Reports = require("./Icons/Reports.svg");
export const Message = require("./Icons/Message.svg");

export const StudentImage = require('./Images/Student_Image.png');
// export const StudentImage2x = require("./Images/Student_Image2x.png");
export const InfoIcon = require('./Icons/InfoIcon.svg');
export const NotificationBell = require('./Icons/NotificationBell.svg');
// export const NextIcon = require("./Icons/Next.svg")
export const Meeting = require('./Icons/meeting.svg');
// export const Guest = require("./Images/guest.png");
export const instagramIcon = require('./Icons/instagram.svg');
export const facebookIcon = require('./Icons/facebook.svg');
export const linkedinIcon = require('./Icons/linkedin.svg');
export const MessageSymbol = require('./Icons/MessageSymbol.svg');
// export const NENLogo = require("./Images/NEN-White.png");
export const Diamond = require("./Icons/Diamond.svg");
export const Sofa = require("./Icons/Sofa.svg");
export const Certificate = require("./Icons/Certificate.svg");
export const WOWhiteLogo = require('./Icons/WOWhiteLogo.svg');
export const MoreInfo = require('./Icons/More_Info.svg');
export const WhiteCertificate = require("./Icons/whiteCertificate.svg");

// WO-CODE-VARIANT
export const background = require('./Images-wo/background.svg');
export const batch_enroll = require('./Images-wo/batch_enroll.png');
export const Calendar = require('./Images-wo/calendar.svg');
export const copy = require('./Images-wo/copy.svg');
export const cross = require('./Images-wo/cross.svg');
export const e4e4e5 = require('./Images-wo/e4e4e5.png');
export const guest_banner = require('./Images-wo/guest_banner.jpg');
export const Ham = require('./Images-wo/ham.svg');
export const img1 = require('./Images-wo/img1.png');
export const img2 = require('./Images-wo/img2.png');
export const img3 = require('./Images-wo/img3.png');
export const more_options = require('./Images-wo/more-options.svg');
export const popup_close = require('./Images-wo/popup-close.svg');
export const profile_icon = require('./Images-wo/profile_icon.svg');
export const rectangle_icon = require('./Images-wo/rectangle-icon.svg');
export const Students = require('./Images-wo/students.svg');
export const WF_Color = require('./Images-wo/WF-Color.svg');
export const WF_Logo_Color = require('./Images-wo/WF-Logo-Color.png');
// @ts-ignore

export const homeIcon = require('./Icons/HomeIcon.svg');
export const calendarIcon = require('./Icons/CalendarIcon.svg');
export const reportsIcon = require('./Icons/ReportsIcon.svg');
export const WO_Icon = require('./Images/WS_ICON.png');
// @ts-ignore
export const Logo = platform.WITP ? require('./Images/logo.png') : require('./Images/Opportunity.png');
export const youtubeIcon = require('./Icons/youtube.svg');
export const BadgeIcon = require('./Icons/Badge.svg');
export const CourseIcon = require('./Icons/Course.svg');
export const Transformation = require("./Icons/Recycle.svg");
export const TransparentBadge = require("./Icons/TransparentBadge.svg");
export const Knowledge = require("./Icons/Knowledge.svg");
export const TransparentCourse = require("./Icons/TransparentCourse.svg");
export const TransparentGroup = require("./Icons/TransparentGroup.svg");
export const NotFound = require('./Images/NotFound.png');
export const NotFound404 = require('./Images/NotFound404.png');
export const MeetingColored = require('./Icons/meetingColored.svg');
export const Trash = require('./Icons/trash.svg');

export const TickIcon = require('./Icons/small-tick.svg');
export const CloseIcon = require('./Images/closeIcon.svg');
export const ArrowDown = require('./Icons/ArrowDown.svg');
export const ArrowLeft = require('./Icons/arrow-left.svg');
export const SendIcon = require('./Icons/Send.svg');
export const Danger = require('./Icons/Danger.svg');
export const DangerOutline = require('./Icons/Danger-outline.svg');
export const GreenCheck = require('./Icons/GreenCheck.svg');
export const NoReport = require('./Images/NoReport.png');
export const ArrowToggle = require('./Icons/ArrowToggle.svg');
export const LikeIcon = require('./Icons/like.svg');
export const ActiveLine = require('./Icons/ActiveLine.svg');
export const DisabledLine = require('./Icons/DisabledLine.svg');
export const Play = require('./Icons/Play.svg');
export const darkArrowLeft = require('./Images/arrow-left-dark.svg');
export const likeIcon = require('./Images/like-heart.svg');
export const disLikeIcon = require('./Images/dislike-heart.svg');
export const libraryIcon = require('./Images/libraryIcon.svg');
export const Google = require('./Icons/Google.svg');
export const ArrowRight = require('./Icons/arrowRight.svg');
export const TimeIconOrange = require("./Icons/Time_Icon_Orange.svg");
export const CalendarOrange = require("./Icons/calendar_orange.svg");
export const Education = require('./Icons/education.svg');
export const Community = require('./Icons/Community.svg');
export const ManageStudents = require('./Icons/manage-accounts.svg');
export const CopyToClip = require('./Icons/copy.svg');
export const ExcelCross = require('./Icons/Excel_Cross.svg');
export const zoomSetting = require('./Icons/zoom.png');
export const accountsSetting = require('./Icons/refresh.svg');
export const googleCalendar = require('./Icons/googleCalendar.svg');
export const settings = require('./Icons/setting.svg');
export const rightArrow = require('./Icons/Arrow-Right.svg');
export const Circle = require('./Icons/circleoutline.svg');
export const InProgress = require('./Icons/inprogress.svg');
export const PreviousOutline = require("./Icons/PreviousOutline.svg");
export const NextOutline = require("./Icons/NextOutline.svg");
export const FullScreen = require("./Icons/Fullscreen.svg");
export const StepsCompleted = require("./Icons/StepsCompleted.svg");
export const TrashColor = require('./Icons/trash-color.svg');
export const AnnouncementIcon = require("./Icons/announcement.svg");
export const MessageColor = require("./Icons/ChatColor.svg");
export const CertificateDownload = require("./Icons/certificate-download.svg");
export const CertificateShare = require("./Icons/certificate-share.svg");
export const Fullscreen_Line = require('./Icons/fullscreen-line.svg');
export const Fullscreen_Line_Color = require('./Icons/fullscreen-line-color.svg');
export const CrossOutlined = require('./Icons/crossOutlined.svg');
export const WatchIcon = require('./Icons/watch.svg');
export const GreenCheckmarkIcon = require('./Icons/green-checkmark.svg');
export const DarkLockIcon = require('./Icons/dark-lock.svg');
export const OpenBookIcon = require('./Icons/open-book.svg');
export const LockCourseIcon = require('./Icons/lock.svg');
export const Congratulation = require('./Icons/Congratulation.svg');
export const SquareRightArrow = require('./Icons/SquareRightArrow.svg');
export const Meter = require('./Icons/Meter.svg');
export const People = require('./Icons/People.svg');
export const DragAndDrop = require('./Icons/DragAndDrop.svg');
export const PlayIcon = require('./Icons/PlayIcon.svg');
export const Book = require('./Icons/Book.svg');
export const Language = require('./Icons/Language.svg');
export const Clock = require('./Icons/Clock.svg');
export const WelcomeInterviewImg = require('./Images/welcomeInterview.png');
export const BriefCaseImage = require('./Images/briefcase.svg');
export const InterviewQuestionImage = require('./Images/interview-question.svg');
export const BookImage = require('./Images/book-square.svg');
export const BriefCaseWhiteOutlineImage = require('./Images/briefcase-outline.svg');
export const MessageImage = require('./Images/message-text.svg');
export const BaseLineHistoryImage = require('./Images/baseline-history.svg');
export const AwardImage = require('./Images/award.svg');
export const InterviewLangingPageImage = require('./Images/interview-landing-page-image.svg');
export const InterviewerImage = require('./Images/interviewer-image.svg');
export const StopImage = require('./Images/stop-image.svg');
export const CancelImage = require('./Images/cancel-image.svg');
export const ProfileIconImage = require('./Images/profile-circle.svg');
export const AudioButtonImage = require('./Images/audio-button.svg');
export const InterviewPageImage = require('./Images/interview-page-image.svg');
export const SpeakerOffImage = require('./Images/speaker-off.png');
export const VideoStart = require('./Images/video-start.svg');
export const Person = require('./Icons/Person.svg');
export const PeopleMobile = require('./Icons/PeopleMobile.svg');
export const Apple = require('./Icons/Apple.svg');
export const Android = require('./Icons/Android.svg');
export const MicOnImage = require('./Images/mic-on.png');
export const MicOFFImage = require('./Images/mic-off.png');
export const WFNudgeLogo = require('./Icons/WFNudgeLogo.svg');
export const WhiteCross = require("./Icons/WhiteCross.svg");
export const downloadReportImage = require('./Images/downloadReportImage.svg');
export const downloadReportImageTwo = require('./Images/downloadReportImageTwo.svg');
export const instituteAdminImageOne = require('./Images/institute-admin-image-one.svg');
export const instituteAdminImageTwo = require('./Images/institute-admin-image-two.svg');
export const netEnrolledImage = require('./Images/net-enrolled.svg');
export const completedStudentsImage = require('./Images/completed-students.svg');
export const batchMappingImage = require('./Images/batches-mapping-image.svg');
export const courseMappingImage = require('./Images/seleted-course-donut.svg');
export const instituteAdminBarImage = require('./Images/institute-admin-bar-score-image.svg');
export const Sad = require("./Icons/sad.svg")
export const WFLogo = require('./Images/logo.png');
export const assessmentBasedSkillScoreCardQR = require('./Images/scanAssessmentBasesQR.png');
export const BlueCalendar = require('./Icons/BlueCalendar.svg');
export const BlueClock = require('./Icons/BlueClock.svg');
export const Users = require('./Icons/Users.svg');
export const Ongoing = require('./Icons/Ongoing.svg');
export const SmileyIcon = require('./Icons/formkit_happy.svg');
export const SkillScoreCardBGImage = require('./Images/SkillScoreCardBG.png');

export const PurpleInfo = require('./Icons/PurpleInfo.svg');
export const SmallBlueCalendar = require('./Icons/SmallBlueCalendar.svg');
export const SmallBlueClock = require('./Icons/SmallBlueClock.svg');
export const SmallUsers = require('./Icons/SmallUsers.svg');
export const EmptyStateAttendees = require('./Icons/EmptyStateAttendees.svg');

export const CertificateDownloadBlueIcon = require('./Icons/Certificate-download-blue.svg');
export const MicroCertificateShare = require("./Icons/Micro-certificate-share.svg");
export const CertificateHeaderIcon = require('./Icons/Certificate_Icon.svg');
export const SkillScoreCardIcon = require("./Icons/SkillScoreCard_Icon.svg");
export const OnlineSessionNullState = require("./Icons/OnlineSessionNullState.svg");

export const ForumIcon = require("./Icons/Forum.svg")
export const StopLine = require("./Icons/stop_line.svg");
export const SmallCertificate = require("./Icons/SmallCertificate.svg");
export const SmallCourse = require("./Icons/SmallCourse.svg");
export const SmallScorecard = require("./Icons/SmallScorecard.svg");
export const SmallSkill = require("./Icons/SmallSkill.svg");
export const MediumCertificate = require("./Icons/MediumCertificate.svg");
export const MediumScorecard = require("./Icons/MediumSkillScorecard.svg");
export const PurpleCollectionTick = require("./Icons/PurpleCollectionTick.svg");
export const WhatsappIcon = require("./Icons/WhatsappIcon.svg");
export const Firework = require("./Icons/Firework.svg");
export const SmallDownload = require("./Icons/SmallDownload.svg");
export const SmallShare = require("./Icons/SmallShare.svg")
export const CourseListEmptyState = require("./Icons/CourseListEmptyState.svg")
export const MentorLocation = require("./Icons/locationRed.svg");
export const PlusIcon = require("./Icons/PlusIcon.svg");
export const EditOutlined = require("./Icons/Edit_outlined.svg");
export const MasterCopilotIcon = require('./Icons/Master-Copilot-Icon.png');
export const EmptyCourseIcon = require('./Icons/EmptyCourse_Icon.svg');
export const MinusIcon = require("./Icons/minusIcon.svg");
export const Union = require("./Icons/Union.svg");
export const activityPlayIcon = require("./Icons/ActivityPlayIcon.svg");
export const progressIcon = require("./Icons/Progress.svg");
export const scormIcon = require("./Icons/ScormIcon.svg");
export const bookIcon = require("./Icons/BookIcon.svg");
export const fullScreenColor = require("./Icons/FullScreenClolor.svg");
export const ListButtonIcon = require("./Icons/ButtonList.svg");
export const TickGreen = require("./Icons/Tick.svg");
export const CalendarIcon = require("./Icons/calendar-icon.svg");
export const ClockIcon = require("./Icons/clock-icon.svg");
export const BorderFrame = require("./Icons/border-frame.svg");
export const ArrowLeftDark = require('./Icons/ArrowLeftDark.svg');
export const RightArrowDark = require('./Icons/RightArrowBtn.svg');
export const UserGroupIocn = require('./Icons/UserGroupIocn.svg');
export const ButtonArrowHead = require('./Icons/play-button-arrowhead.svg');
export const PdfActivity = require('./Icons/pdfActivity.svg');
export const NextIcon = require("./Icons/Next.svg");
export const AssessmentIcon = require("./Icons/AssessmentIcon.svg");
export const ProgressIcon = require("./Icons/ProgressIcon.svg");
export const LessonIcon = require("./Icons/LessonIcon.svg");
export const MaximizePlusIcon = require("./Icons/MaximizePlusIcon.svg");
export const MinimizeMinusIcon = require("./Icons/MinimizeMinusIcon.svg");
export const OrangeFullScreenIcon = require("./Icons/Orange_FullScreen.svg");
export const OrangeLeftArrowIcon = require("./Icons/Orange_leftArrow.svg");
export const OrangeRightArrowIcon = require("./Icons/Orange_rightArrow.svg");
export const OrangeMicOnIcon = require("./Icons/Orange_Mic.svg");
export const WhiteUpIcon = require("./Icons/White_Up.svg")
export const AIAssistant = require('./Icons/Master-Copilot-Icon.png');
export const CalComIcon = require('./Icons/calcom.svg');
export const CalenderIcon = require('./Icons/calendar_month.svg');
export const EmptyState = require('./Icons/empty-state.svg');
export const SendTwo = require("./Icons/send-2.svg");
export const Twitter = require("./Icons/twitter.svg");
export const Facebook = require("./Icons/facebook_mentor.svg");
export const Linkedin = require("./Icons/linkedInRound.svg");
export const GrayLocation = require("./Icons/grayLocation.svg");
export const CorrectCheck = require('./Icons/correctCheck.svg');
export const DownloadIcon = require('./Icons/download.svg');
export const ZoomICon = require('./Icons/ZoomLogo.svg');
export const BannerLogo = require('./Images/banner-logo.svg');
export const jobRoleImage = require("./Images/job-role-image.svg");

export const geoGraphicLocation = require("./Icons/geoGraphicLocation.svg");
export const emptyCircle = require("./Images/empty-circle.svg");
export const completedCircle = require("./Images/career-path-circle.svg");
export const aptitudeImage = require("./Images/aptitude-image.svg");
export const employerImage = require("./Images/employer-image.svg");
export const FiltersIcon = require("./Icons/filters.svg");

export const welcome_intro_01 = require("./Images/Welcome_01.svg");
export const welcome_intro_02 = require("./Images/Welcome_02.svg");
export const welcome_intro_03 = require("./Images/Welcome_03.svg");
export const welcome_intro_04 = require("./Images/Welcome_04.svg");
export const welcome_intro_05 = require("./Images/Welcome_05.svg");

export const attributeResponse = require("./Images/submit-attribute-response.svg");
export const attributeProgress = require("./Images/attritute-progress.svg");
export const attributeCompleteStatus = require("./Images/attribute-complete-status.svg");
export const attributeProgressStatus = require("./Images/attribute-progress-status.svg");
export const swapCardIcon = require("./Icons/swap_card_icon.svg");
export const questionnaire = require("./Images/questionnaire.svg");
export const slider = require("./Images/slider.svg");
export const SpeakerOn = require('./Images/speaker-on.svg');
export const PssciveSearchInputIcon = require('./Images/psscive-search-icon.svg');
export const CollegeCategory = require('./Images/college-category.svg');
