import { Language } from '@models/Language';
import { LanguageName } from '@common/domain/models/LanguageName';

import { IState } from "@store/webInterfaces";
import { ITag } from './interface';

const isCommonStateUndefined = (state: IState): boolean =>
  state.common === undefined;

const isCommonErrorsUndefined = (state: IState): boolean =>
  state.common.errors === undefined;

const isCommonLoaderUndefined = (state: IState): boolean =>
  state.common.loaders === undefined;

export const getLangaugesSelector = (state: IState): Language[] => {
  if (isCommonStateUndefined(state) || !state.common.languages) return [];
  return state.common.languages;
};

export const getLanguagesLoaderSelector = (state: IState): boolean => {
  if (
    isCommonStateUndefined(state) ||
    isCommonLoaderUndefined(state) ||
    !state.common.loaders.fetchLanguages
  )
    return false;
  return state.common.loaders.fetchLanguages;
};

export const getLanguagesErrorSelector = (state: IState): string => {
  if (
    isCommonStateUndefined(state) ||
    isCommonErrorsUndefined(state) ||
    !state.common.errors.fetchLanguages
  )
    return "";
  return state.common.errors.fetchLanguages;
};

export const getTagsMapSelector = (state: IState) => {
  return state?.common?.tagsMap || {};
}

export const fetchTagsLoaderSelector = (state: IState): boolean => {
  return state?.common?.loaders?.tags || false;
};

export const fetchTagsErrorSelector = (state: IState): string => {
  return state?.common?.errors.tags || '';
};

export const fetchTagsSelector = (state: IState): ITag[] => {
  return state?.common?.tags || [];
}

export const fetchTagsLanguagesLoaderSelector = (state: IState): boolean => {
  return state?.common?.loaders?.tagsLanguages || false;
};

export const fetchTagsLanguagesErrorSelector = (state: IState): string => {
  return state?.common?.errors.tagsLanguages || '';
};

export const fetchTagsLanguagesSelector = (state: IState): ITag[] => {
  return state?.common?.tagsLanguages || [];
}

export const fetchLanguageNamesLoaderSelector = (state: IState): boolean => {
  return state?.common?.loaders?.languageNames || false;
};

export const fetchLanguageNamesErrorSelector = (state: IState): string => {
  return state?.common?.errors.languageNames || '';
};

export const fetchLanguageNamesSelector = (state: IState): LanguageName[] => {
  return state?.common?.languageNames || [];
}

export const fetchTagsInterviewPrepLanguagesLoaderSelector = (state: IState): boolean => {
  return state?.common?.loaders?.tagsInterviewPrepLanguages || false;
};

export const fetchTagsInterviewPrepLanguagesErrorSelector = (state: IState): string => {
  return state?.common?.errors.tagsInterviewPrepLanguages || '';
};

export const fetchTagsInterviewPrepLanguagesSelector = (state: IState): ITag[] => {
  return state?.common?.tagsInterviewPrepLanguages || [];
}

export const fetchProductToursSelector = (state: IState): string[] => {
  return state?.common?.productTours || [];
}


export const getAssessmentCompetencySelector = (state: IState) => state?.common?.assessmentCompetencyList || [];
export const getAssessmentCompetencyLoaderSelector = (state: IState) => state?.common?.loaders?.assessmentCompetencyList || false;
export const getAssessmentCompetencyErrorSelector = (state: IState) => state?.common?.errors?.assessmentCompetencyList || "";

export const getSectorListSelector = (state: IState) => state?.common?.sectorList || [];
export const getSectorListLoaderSelector = (state: IState) => state?.common?.loaders?.sectorList || false;
export const getSectorListErrorSelector = (state: IState) => state?.common?.errors?.sectorList || "";

export const getDegreeListSelector = (state: IState) => state?.common?.degreeList || [];
export const getDegreeListLoaderSelector = (state: IState) => state?.common?.loaders?.degreeList || false;
export const getDegreeListErrorSelector = (state: IState) => state?.common?.errors?.degreeList || "";

export const getLanguageJsonLoaderSelector = (state: IState) => state?.common?.loaders?.fetchLanguageJson || false;

