export const mentorMessages = {
         landingPageTitle: {
           key: "mentor.labelsAndTitles.LANDING_PAGE_TITLE",
         },
         generalMentor: {
           key: "mentor.labelsAndTitles.GENERAL_MENTOR",
         },
         aboutUs: {
           key: "mentor.labelsAndTitles.ABOUT_US",
         },
         industryMentor: {
           key: "mentor.labelsAndTitles.INDUSTRY_MENTOR",
         },
         jobRoleMentor: {
           key: "mentor.labelsAndTitles.JOB_ROLE_MENTOR",
         },
         whenToChoose: {
           key: "mentor.labelsAndTitles.WHEN_TO_CHOOSE",
         },
         connectWithExpert: {
           key: "mentor.labelsAndTitles.CONNECT_WITH_EXPERT",
         },
         explore: {
           key: "mentor.actions.EXPLORE",
         },
         sectors: {
           key: "mentor.labelsAndTitles.SECTORS",
         },
         jobRole: {
           key: "mentor.labelsAndTitles.JOB_ROLE",
         },
         bookSession: {
           key: "mentor.actions.BOOK_SESSION",
         },
         chat: {
           key: "global.actions.CHAT",
         },
         na: {
           key: "dashboard.API_COURSE_STATUS.null",
         },
         welcome: {
           key: "login.welcome",
         },
         yourAvailability: {
           key: "mentor.actions.YOUR_AVAILABILITY",
         },
         emptyStateText: {
           key: "mentor.labelsAndTitles.EMPTY_STATE_TEXT",
         },
         emptyStateDesText: {
           key: "mentor.labelsAndTitles.EMPTY_STATE_DES_TEXT",
         },
         more: {
           key: "mentor.actions.MORE",
         },
         viewLess: {
           key: "mentor.actions.VIEW_LESS",
         },
         generalMentorsBenefits: {
           key: "mentor.labelsAndTitles.GENERAL_MENTOR_BENEFITS",
         },
         industryMentorsBenefits: {
           key: "mentor.labelsAndTitles.INDUSTRY_MENTOR_BENEFITS",
         },
         jobRoleMentorsBenefits: {
           key: "mentor.labelsAndTitles.JOB_ROLE_MENTOR_BENEFITS",
         },
         generalMentorsDesTitle: {
           key: "mentor.labelsAndTitles.GENERAL_MENTOR_DES_TITLE",
         },
         industryMentorsDesTitle: {
           key: "mentor.labelsAndTitles.INDUSTRY_MENTOR_DES_TITLE",
         },
         jobRoleMentorsDesTitle: {
           key: "mentor.labelsAndTitles.JOB_ROLE_MENTOR_DES_TITLE",
         },
         careerGuidance: {
           key:
             "mentor.labelsAndTitles.GENERAL_MENTOR_TAG.CAREER_GUIDANCE",
         },
         interviewBasics: {
           key:
             "mentor.labelsAndTitles.GENERAL_MENTOR_TAG.INTERVIEW_BASICS",
         },
         sectorSpecific: {
           key:
             "mentor.labelsAndTitles.INDUSTRY_MENTOR_TAG.SECTOR_SPECIFIC",
         },
         marketTrends: {
           key:
             "mentor.labelsAndTitles.INDUSTRY_MENTOR_TAG.MARKET_TRENDS",
         },
         jobRoleFocus: {
           key:
             "mentor.labelsAndTitles.JOB_ROLE_MENTOR_TAG.JOB_ROLE_FOCUS",
         },
         progressionPath: {
           key:
             "mentor.labelsAndTitles.JOB_ROLE_MENTOR_TAG.PROGRESSION_PATH",
         },
         generalDesFirst: {
           key: "mentor.labelsAndTitles.GENERAL_MENTOR_DES.FIRST",
         },
         generalDesSecond: {
           key: "mentor.labelsAndTitles.GENERAL_MENTOR_DES.SECOND",
         },
         industryDesFirst: {
           key: "mentor.labelsAndTitles.INDUSTRY_MENTOR_DES.FIRST",
         },
         industryDesSecond: {
           key: "mentor.labelsAndTitles.INDUSTRY_MENTOR_DES.SECOND",
         },
         jobRoleDesFirst: {
           key: "mentor.labelsAndTitles.JOB_ROLE_MENTOR_DES.FIRST",
         },
         jobRoleDesSecond: {
           key: "mentor.labelsAndTitles.JOB_ROLE_MENTOR_DES.SECOND",
         },
         location: {
           key: "global.labelsAndTitles.LOCATION_LABEL",
         },
         country: {
           key: "auth.labelsAndTitles.COUNTRY",
         },
         reset: {
           key: "mentor.actions.RESET",
         },
         apply: {
           key: "instituteAdmin.labelsAndTitles.APPLY",
         },
         search: {
           key: "mentor.labelsAndTitles.SEARCH",
         },
         notFound: {
           key: "global.errors.NO_RESULT_FOUND",
         },
         searchMentors: {
           key: "mentor.labelsAndTitles.SEARCH_MENTORS",
         },
         cityNotFound: {
           key: "register.LocationNotFound",
         },
         language: {
           key: "courseDetails.labelsAndTitles.LANGUAGE",
         },
         experience: {
           key: "mentor.labelsAndTitles.EXPERIENCE",
         },
         sector: {
           key: "courseDetails.labelsAndTitles.SECTOR",
         },
         jobroleFilter: {
           key: "mentor.labelsAndTitles.JOB_ROLE_FILTER",
         },
         noResultFound: {
           key: "messages.messages.NO_SEARCH_RESULT_MESSAGE",
         },
         noJobRole: {
           key: "mentor.labelsAndTitles.NO_JOBROLE",
         },
         noSector: {
           key: "mentor.labelsAndTitles.NO_SECTOR",
         },
         mentorType: {
           key: "mentor.labelsAndTitles.MENTOR_TYPE",
         },
         aspiringSectors: {
           key: "mentor.labelsAndTitles.ASPIRING_SECTORS",
         },
         currentMentees: {
           key: "mentor.labelsAndTitles.CURRENT_MENTEES",
         },
       };






