import { addI18Resource, addCourseI18Resource } from '@utils/LanguageUtils';

import { IFileRepository } from '@repositories/interfaces';


import { ApiClient } from '@network/client';
import { HTTP_STATUS_CODES } from '@network/constants';
import { IApiClient } from '@network/interfaces';
import axios from 'axios';
import { WebStoreProviderService } from '@store/WebStoreProviderService';
import { fetchLanguageJson, fetchLanguageJsonSuccess } from '@common/redux/common/actions';

enum selectType {
  User = "user",
  Course = "course",
}
class FileRepository implements IFileRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public getLanguageJson = async (url: string, languageKey: string, type : string=selectType.User): Promise<any> => {
    try {
      WebStoreProviderService.getStore().dispatch(fetchLanguageJson());
      const response = await axios.get(url);
      if (HTTP_STATUS_CODES.Ok === response.status && response.data) {
        if(type === selectType.Course){
          addCourseI18Resource(languageKey, 'cou', response.data, true);
        }else{
          addI18Resource(languageKey, 'nen', response.data, true);
        }
        WebStoreProviderService.getStore().dispatch(fetchLanguageJsonSuccess());
      }
    } catch (error) { }
  }
}
const fileRepository = new FileRepository(ApiClient);

export { fileRepository as FileRepository };