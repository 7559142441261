import { authActions, fetchAzureTokenActions } from '@redux/auth/actions';

import { IAuthState } from './interface';

export const initialState: IAuthState = {
    isWebView: false,
    login: {},
    changePassword: false,
    forgotPassword: null,
    checkUserExist: null,
    register: null,
    resendVerifyMail: null,
    isAuthenticated: false,
    isBetaUser:false,
    resetPassword: false,
    isFirstTimeUser: false,
    emailVerification: "",
    maintenance: null,
    nsdcSsoData: "",
    authoriseSsoUser: {},
    resendOtp: false,
    fetchAzureToken: null,
    loaders: {
        login: false,
        logout: false,
        checkUserExist: false,
        register: false,
        resendVerifyMail: false,
        forgotPassword: false,
        changePassword: false,
        resetPassword: false,
        emailVerification: false,
        nsdcSsoData: false,
        authoriseSsoUser: false,
        resendOtp: false,
        fetchAzureToken: false
    },
    errors: {
        login: '',
        logout: '',
        checkUserExist: '',
        register: '',
        resendVerifyMail: '',
        forgotPassword: '',
        changePassword: '',
        resetPassword: '',
        emailVerification: '',
        nsdcSsoData: '',
        authoriseSsoUser: '',
        resendOtp: '',
        fetchAzureToken: ''
    }
};

export const authReducer = (state: IAuthState = initialState,
    action: { type: string; payload: any }) => {
    switch (action.type) {
    case authActions.SET_FIRST_TIME_USER:
        return {
            ...state,
            isFirstTimeUser: action.payload,
        }
    case authActions.SET_WEBVIEW:
        return {
            ...state,
            isWebView: true,
            isAuthenticated: true // to fix mobile platform pages navigation
        };
    case authActions.LOGIN:
    case authActions.SSO_LOGIN:
    case authActions.SOCIAL_MEDIA_LOGIN:
        return {
            ...state,
            loaders: { ...state.loaders, login: true },
            errors: { ...state.errors, login: '' },
        };
    case authActions.LOGIN_SUCCESS:
        return {
            ...state,
            login: action.payload,
            loaders: { ...state.loaders, login: false },
        };
    case authActions.LOGIN_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, login: false },
            errors: { ...state.errors, login: action.payload },
        };

    case authActions.CHECK_USER_EXIST:
        return {
            ...state,
            loaders: { ...state.loaders, checkUserExist: true },
            errors: { ...state.errors, checkUserExist: '' },
        };
    case authActions.CHECK_USER_EXIST_SUCCESS:
        return {
            ...state,
            checkUserExist: action.payload,
            loaders: { ...state.loaders, checkUserExist: false },
        };
    case authActions.CHECK_USER_EXIST_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, checkUserExist: false },
            errors: { ...state.errors, checkUserExist: action.payload },
        };

    case authActions.REGISTER:
        return {
            ...state,
            loaders: { ...state.loaders, register: true },
            errors: { ...state.errors, register: '' },
        };
    case authActions.REGISTER_SUCCESS:
        return {
            ...state,
            register: action.payload,
            loaders: { ...state.loaders, register: false },
        };
    case authActions.REGISTER_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, register: false },
            errors: { ...state.errors, register: action.payload },
        };

    case authActions.RESEND_VERIFY_MAIL:
        return {
            ...state,
            loaders: { ...state.loaders, resendVerifyMail: true },
            errors: { ...state.errors, resendVerifyMail: '' },
        };
    case authActions.RESEND_VERIFY_MAIL_SUCCESS:
        return {
            ...state,
            checkUserExist: null,
            resendVerifyMail: action.payload,
            loaders: { ...state.loaders, resendVerifyMail: false },
        };
    case authActions.RESEND_VERIFY_MAIL_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, resendVerifyMail: false },
            errors: { ...state.errors, resendVerifyMail: action.payload },
        };

    case authActions.FORGOT_PASSWORD:
        return {
            ...state,
            loaders: { ...state.loaders, forgotPassword: true },
            errors: { ...state.errors, forgotPassword: '' },
        };
    case authActions.FORGOT_PASSWORD_SUCCESS:
        return {
            ...state,
            forgotPassword: action.payload,
            loaders: { ...state.loaders, forgotPassword: false },
        };
    case authActions.FORGOT_PASSWORD_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, forgotPassword: false },
            errors: { ...state.errors, forgotPassword: action.payload },
        };

    case authActions.CHANGE_PASSWORD:
        return {
            ...state,
            loaders: { ...state.loaders, changePassword: true },
            errors: { ...state.errors, changePassword: '' },
        };
    case authActions.CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            changePassword: action.payload,
            loaders: { ...state.loaders, changePassword: false },
        };
    case authActions.CHANGE_PASSWORD_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, changePassword: false },
            errors: { ...state.errors, changePassword: action.payload },
        };

    case authActions.SET_AUTHENTICATED_FLAG:
        return {
            ...state,
            isAuthenticated: action.payload,
        };

    case authActions.LOGOUT:
        return {
            ...state,
            loaders: {...state.loaders, logout: true},
            errors: {...state.errors, logout: ''}
        };

    case authActions.LOGOUT_SUCCESS:
        return {
            ...state,
            error: {},
            isAuthenticated: false,
            loaders: {...state.loaders, logout: false},
        };

    case authActions.LOGOUT_FAIL:
        return {
            ...state,
            loaders: {...state.loaders, logout: false},
            errors: {...state.errors, logout: action.payload}
        };
    case authActions.RESET_FORGOT_PASSWORD_DATA:
        return {
            ...state,
            forgotPassword: null,
            checkUserExist: null,
            resetPassword: false,
            errors: {
                ...state.errors,
                checkUserExist: '',
                forgotPassword: '',
                resetPassword: '',
            },
            loaders: {
                ...state.loaders,
                checkUserExist: false,
                forgotPassword: false,
                resetPassword: false,
            },
        };
    case authActions.RESET_PASSWORD:
        return {
            ...state,
            loaders: { ...state.loaders, resetPassword: true },
            errors: { ...state.errors, resetPassword: '' },
        };
    case authActions.RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            resetPassword: action.payload,
            loaders: { ...state.loaders, resetPassword: false },
        };
    case authActions.RESET_PASSWORD_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, resetPassword: false },
            errors: { ...state.errors, resetPassword: action.payload },
        };
    // email verification
    case authActions.EMAIL_VERIFICATION:
        return {
            ...state,
            checkUserExist: null,
            loaders: { ...state.loaders, emailVerification: true },
            errors: { ...state.errors, emailVerification: ""}
        }
    case authActions.EMAIL_VERIFICATION_SUCCESS:
        return {
            ...state,
            emailVerification: action.payload,
            loaders: { ...state.loaders, emailVerification: false },
            errors: { ...state.errors, emailVerification: ""}
        }
    case authActions.EMAIL_VERIFICATION_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, emailVerification: false },
            errors: { ...state.errors, emailVerification: action.payload }
        }
    case authActions.SET_MAINTENANCE_FLAG:
        return {
            ...state,
            maintenance: action.payload
        }
    // NSDC Flow
    case authActions.GET_NSDC_SSO_URL:
            return {
                ...state,
                nsdcSsoData: "",
                loaders: { ...state.loaders, nsdcSsoData: true },
            };
    case authActions.GET_NSDC_SSO_URL_SUCCESS:
            return {
                ...state,
                nsdcSsoData: action.payload,
                loaders: { ...state.loaders, nsdcSsoData: false },
            };
    case authActions.GET_NSDC_SSO_URL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, nsdcSsoData: false },
                errors: { ...state.errors, nsdcSsoData: action.payload },
            };
    case authActions.AUTHORISE_SSO_USER:
            return {
                ...state,
                loaders: { ... state.loaders, authoriseSsoUser: true },
                errors: { ...state.errors, authoriseSsoUser: ""}
            }
    case authActions.AUTHORISE_SSO_USER_SUCCESS:
            return {
                ...state,
                authoriseSsoUser: action.payload,
                loaders: { ... state.loaders, authoriseSsoUser: false },
                errors: { ...state.errors, authoriseSsoUser: ""}
            }
    case authActions.AUTHORISE_SSO_USER_FAIL:
            return {
                ...state,
                loaders: { ... state.loaders, authoriseSsoUser: false },
                errors: { ...state.errors, authoriseSsoUser: action.payload}
            }

    case authActions.SET_BETA_USER:
          return {
              ...state,
              isBetaUser: action.payload,
          };
    case authActions.RESEND_OTP:
        return {
            ...state,
            loaders: { ...state.loaders, resendOtp: true },
            errors: { ...state.errors, resendOtp: '' },
        };
    case authActions.RESEND_OTP_SUCCESS:
        return {
            ...state,
            checkUserExist: null,
            resendOtp: true,
            loaders: { ...state.loaders, resendOtp: false },
        };
    case authActions.RESEND_OTP_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, resendOtp: false },
            errors: { ...state.errors, resendOtp: action.payload },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN:
        return {
            ...state,
            loaders: { ...state.loaders, fetchAzureToken: true },
            errors: { ...state.errors, fetchAzureToken: '' },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN_SUCCESS:
        return {
            ...state,
            fetchAzureToken: action.payload,
            loaders: { ...state.loaders, fetchAzureToken: false },
            errors: { ...state.errors, fetchAzureToken: '' },
        };
    case fetchAzureTokenActions.FETCH_AZURE_TOKEN_FAIL:
        return {
            ...state,
            loaders: { ...state.loaders, fetchAzureToken: false },
            errors: { ...state.errors, fetchAzureToken: action.payload },
        };                  
    default:
        return state;
    }
};
