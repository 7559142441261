import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Tabs } from "antd";

import Navigator from "@navigator/index";

import Text, { FontFamilyVariant, TextType } from '@components/Text';

import { IAppMenuItem } from "@layout/Dashboard";
import { isQuestionnareCompleted } from "@presentation/CareerAdvisor/utils";
import { connect } from "react-redux";
import { fetchAttributeGroups } from "@common/redux/careerAdvisor/actions";
import { getAttributesGroup } from "@common/redux/careerAdvisor/selectors";
import { IAttributes } from "@common/domain/models/AttributeGroups";
import { careerAdvisorMessages } from "@containers/CareerAdvisor/messages";
import { translate } from "@translations/Translater";
import { Cross } from "@assets/index";
import { WFImg } from "@components/index";
import { PrimaryButton } from '@components/Button';
import { ShareReportMenuIcon } from "@assets/images";
import "./styles.less";


const { TabPane } = Tabs;

interface IProps {
  tabItems: IAppMenuItem[];
  className?: string;
  activeKey: string;
  onChange: (key: string) => void;
  tabPosition?: string;
  size?: any;
  attributesGroup: IAttributes[]
  fetchAttributeGroups: () => void;
}

export const TabView = (props: IProps) => {
  const { activeKey, size, className, tabItems, onChange, attributesGroup, fetchAttributeGroups } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(false)
  }

  const style = ["tab_container", className].join(" ");

  const tabItemClicked = (item: string) => {
    props.onChange(item);
  };

  useEffect(() => {
    if (!attributesGroup.length) {
      fetchAttributeGroups();
    }
  }, [attributesGroup]);

  return (
    <>
    <Tabs
      className={style}
      onChange={tabItemClicked}
      activeKey={activeKey}
      size={size}
      centered
    >
      {tabItems.map((tabItem: IAppMenuItem) => {
        const isTabLocked = tabItem?.locked === true && isQuestionnareCompleted(attributesGroup) === false ? true : false;
        const onClick = () => {
          if (isTabLocked) {
            setOpenModal(true);
          } else {
          const route = tabItem.url;
          Navigator.push(route);
          onChange(tabItem.key);
        }
      };

        return (
            <TabPane
            key={tabItem.key}
            tab={
              <div className="tab_menu" onClick={onClick} id={`mobile_${tabItem.name.toString().split(" ")[0]}`}>
                {isTabLocked ? <WFImg src={ShareReportMenuIcon} alt="Share-Report"/> : tabItem.iconImage}
                {/* {`${tabItem.name}`} */}
                <p>{tabItem.name.toString().split(" ")[0]} {tabItem.name.toString().split(" ")[1]}</p>
              </div>
            }
          />
        );
      })}
    </Tabs>
    <Modal
        open={openModal}
        className="modal-container"
        footer={false}
        closable={false}
        onCancel={toggleModal}
        centered
      >
        <div className='modal-parent'>
          <div className='title-container'>
            <Text
              textType={TextType.subHeading1}
              fontFamilyVariant={FontFamilyVariant.semibold}
              text={translate(t, careerAdvisorMessages.lockedShareReportTitle)}
              className="title"
            />
            <WFImg src={Cross} alt='Cross Icon' className='cross-image' onClick={toggleModal} />
          </div>
          <Text
            textType={TextType.h4}
            fontFamilyVariant={FontFamilyVariant.regular}
            text={translate(t, careerAdvisorMessages.lockedShareReportDescription)}
            className="desc"
          />
          <PrimaryButton htmlType='button' className="close-button" onClick={toggleModal}>{translate(t, careerAdvisorMessages.close)}</PrimaryButton>
        </div>

      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attributesGroup: getAttributesGroup(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchAttributeGroups: () => dispatch(fetchAttributeGroups())
});

export default memo(connect(
  mapStateToProps, mapDispatchToProps
)(TabView));

