import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import history from '@utils/history';

import {
    authReducer, initialState as authInitialState,
} from '@common/redux/auth/reducer';

import {
    commonReducer,
    initialState as commonInitialState,
} from '@common/redux/common/reducer';

import {
    initialState as profileInitialState,
    profileReducer,
} from '@common/redux/profile/reducer';

import { authActions } from '@redux/auth/actions';

import {
    initialState as locationInitialState,
    locationReducer,
} from '@redux/location/reducer';

import { careerAdvisorReducer ,
    initialState as careerAdvisorInitialState,
} from '@common/redux/careerAdvisor/reducer';

import {
    initialState as rootInitialState,
    rootReducer,
} from '@redux/root/reducer';
import authWhiteList from '@store/transform/auth';
import { connectRouter } from 'connected-react-router';


export const rootPersistConfig = {
    key: 'root', // key is required
    storage, // storage is now required
    transforms: [authWhiteList],
};

const reducer = persistReducer(
    rootPersistConfig,
    combineReducers({
        router: connectRouter(history),
        location: locationReducer,
        profile: profileReducer,
        auth: authReducer,
        common: commonReducer,
        careerAdvisor: careerAdvisorReducer,
        root: rootReducer,
    })
);

const resetToIntialState = (state: any) => ({
    ...state,
    location: locationInitialState,
    profile: profileInitialState,
    auth: authInitialState,
    common: commonInitialState,
    careerAdvisor: careerAdvisorInitialState,
    root: rootInitialState
});

// to clear redux after user triggers logout
export default (state: any, action: any) => {
    if (
        action.type === authActions.CLEAR_USER
        || action.type === authActions.LOGOUT_SUCCESS
    ) {
        return resetToIntialState(state);
    }
    return reducer(state, action);
};
