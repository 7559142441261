import { Language } from "@common/domain/models/Language";
import { LanguageName, ILanguageNamePayload } from "@common/domain/models/LanguageName";

import { ITagPayload, IFetchTagsAction } from "./interface";

const actionTypePrefix = "COMMON/";

export const commonActions = {
  FETCH_LANGUAGES: `${actionTypePrefix}FETCH_LANGUAGES`,
  FETCH_LANGUAGES_SUCCESS: `${actionTypePrefix}FETCH_LANGUAGES_SUCCESS`,
  FETCH_LANGUAGES_FAIL: `${actionTypePrefix}FETCH_LANGUAGES_FAIL`,

  FETCH_TAGS: `${actionTypePrefix}FETCH_TAGS`,
  FETCH_TAGS_SUCCESS: `${actionTypePrefix}FETCH_TAGS_SUCCESS`,
  FETCH_TAGS_FAIL: `${actionTypePrefix}FETCH_TAGS_FAIL`,

  FETCH_TAGS_LANGUAGES: `${actionTypePrefix}FETCH_TAGS_LANGUAGES`,
  FETCH_TAGS_LANGUAGES_SUCCESS: `${actionTypePrefix}FETCH_TAGS_LANGUAGES_SUCCESS`,
  FETCH_TAGS_LANGUAGES_FAIL: `${actionTypePrefix}FETCH_TAGS_LANGUAGES_FAIL`,

  FETCH_LANGUAGES_NAMES: `${actionTypePrefix}FETCH_LANGUAGES_NAMES`,
  FETCH_LANGUAGES_NAMES_SUCCESS: `${actionTypePrefix}FETCH_LANGUAGES_NAMES_SUCCESS`,
  FETCH_LANGUAGES_NAMES_FAIL: `${actionTypePrefix}FETCH_LANGUAGES_NAMES_FAIL`,

  FETCH_TAGS_INTERVIEW_PREP_LANGUAGES: `${actionTypePrefix}FETCH_TAGS_INTERVIEW_PREP_LANGUAGES`,
  FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_SUCCESS: `${actionTypePrefix}FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_SUCCESS`,
  FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_FAIL: `${actionTypePrefix}FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_FAIL`,

  FETCH_ASSESSMENT_COMPETENCYLIST: `${actionTypePrefix}/FETCH_ASSESSMENT_COMPETENCYLIST`,
  FETCH_ASSESSMENT_COMPETENCYLIST_SUCCESS: `${actionTypePrefix}/FETCH_ASSESSMENT_COMPETENCYLIST_SUCCESS`,
  FETCH_ASSESSMENT_COMPETENCYLIST_FAIL: `${actionTypePrefix}/FETCH_ASSESSMENT_COMPETENCYLIST_FAIL`,

  FETCH_PRODUCT_TOURS: `${actionTypePrefix}FETCH_PRODUCT_TOURS`,
  FETCH_PRODUCT_TOURS_SUCCESS: `${actionTypePrefix}FETCH_PRODUCT_TOURS_SUCCESS`,
  FETCH_PRODUCT_TOURS_FAIL: `${actionTypePrefix}FETCH_PRODUCT_TOURS_FAIL`,

  FETCH_SECTOR_LIST: `${actionTypePrefix}FETCH_SECTOR_LIST`,
  FETCH_SECTOR_LIST_SUCCESS: `${actionTypePrefix}FETCH_SECTOR_LIST_SUCCESS`,
  FETCH_SECTOR_LIST_FAIL: `${actionTypePrefix}FETCH_SECTOR_LIST_FAIL`,

  FETCH_DEGREE_LIST: `${actionTypePrefix}FETCH_DEGREE_LIST`,
  FETCH_DEGREE_LIST_SUCCESS: `${actionTypePrefix}FETCH_DEGREE_LIST_SUCCESS`,
  FETCH_DEGREE_LIST_FAIL: `${actionTypePrefix}FETCH_DEGREE_LIST_FAIL`,

  FETCH_LANGUAGE_JSON: `${actionTypePrefix}FETCH_LANGUAGE_JSON`,
  FETCH_LANGUAGE_JSON_SUCCESS: `${actionTypePrefix}FETCH_LANGUAGE_JSON_SUCCESS`,
  FETCH_LANGUAGE_JSON_FAIL: `${actionTypePrefix}FETCH_LANGUAGE_JSON_FAIL`,
};

export interface IFetchLanguagesAction {
  type: string;
}

export interface IFetchLanguagesSuccessAction {
  type: string;
  payload: Language[];
}

export interface IFetchLanguageNameAction {
  type: string;
  payload: ILanguageNamePayload
}

export interface IFetchLanguageNameSuccessAction {
  type: string;
  payload: LanguageName[];
}


export interface IFetchAssessmentCompetency {
  products?: string;
  scopes?: string | string[];
  keys?:string;
  languageCodes?:string;
}

export interface IFetchAssessmentCompetencyAction {
  type?: string;
  payload?: IFetchAssessmentCompetency;
}

export interface IFetchProductTour {
  products?: string;
  scopes?: string;  
  keys?: string;
}

export interface IFetchProductTourAction {
  type?: string;
  payload?: IFetchProductTour;
}

export const fetchLanguages = (): IFetchLanguagesAction => {
  return {
    type: commonActions.FETCH_LANGUAGES,
  };
};

export const fetchLanguagesSuccess = (
  languages: Language[]
): IFetchLanguagesSuccessAction => {
  return {
    type: commonActions.FETCH_LANGUAGES_SUCCESS,
    payload: languages,
  };
};

export const fetchLanguagesFailure = (error: string) => {
  return {
    type: commonActions.FETCH_LANGUAGES_FAIL,
    payload: error,
  };
};

export const fetchTags = (payload: ITagPayload): IFetchTagsAction => {
  return {
      type: commonActions.FETCH_TAGS,
      payload
  };
};

export const fetchTagsSuccess = (payload) => {
  return {
      type: commonActions.FETCH_TAGS_SUCCESS,
      payload
  };
};

export const fetchTagsFail = (error: string) => {
  return {
      type: commonActions.FETCH_TAGS_FAIL,
      payload: error
  };
};

export const fetchTagsLanguages = (payload: ITagPayload): IFetchTagsAction => {
  return {
      type: commonActions.FETCH_TAGS_LANGUAGES,
      payload
  };
};

export const fetchTagsLanguagesSuccess = (payload) => {
  return {
      type: commonActions.FETCH_TAGS_LANGUAGES_SUCCESS,
      payload
  };
};

export const fetchTagsLanguagesFailure = (error: string) => {
  return {
      type: commonActions.FETCH_TAGS_LANGUAGES_FAIL,
      payload: error
  };
};

export const fetchLanguageNames = (payload: ILanguageNamePayload): IFetchLanguageNameAction => {
  return {
    type: commonActions.FETCH_LANGUAGES_NAMES,
    payload
  };
};

export const fetchLanguageNamesSuccess = (
  languages: LanguageName[]
): IFetchLanguageNameSuccessAction => {
  return {
    type: commonActions.FETCH_LANGUAGES_NAMES_SUCCESS,
    payload: languages,
  };
};

export const fetchLanguageNamesFailure = (error: string) => {
  return {
    type: commonActions.FETCH_LANGUAGES_NAMES_FAIL,
    payload: error,
  };
};

export const fetchTagsInterviewPrepLanguages = (payload: ITagPayload): IFetchTagsAction => {
  return {
      type: commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES,
      payload
  };
};

export const fetchTagsInterviewPrepLanguagesSuccess = (payload) => {
  return {
      type: commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_SUCCESS,
      payload
  };
};

export const fetchTagsInterviewPrepLanguagesFailure = (error: string) => {
  return {
      type: commonActions.FETCH_TAGS_INTERVIEW_PREP_LANGUAGES_FAIL,
      payload: error
  };
};

export const fetchAssessmentCompetency = (payload: IFetchAssessmentCompetency): IFetchAssessmentCompetencyAction => {
  return {
    type: commonActions.FETCH_ASSESSMENT_COMPETENCYLIST,
    payload
  }
}
export const fetchAssessmentCompetencySuccess = (payload: any) => {
  return {
    type: commonActions.FETCH_ASSESSMENT_COMPETENCYLIST_SUCCESS,
    payload
  }
}
export const fetchAssessmentCompetencyFail = (error: string) => {
  return {
    type: commonActions.FETCH_ASSESSMENT_COMPETENCYLIST_FAIL,
    payload: error
  }
}



export const fetchProductTours = (payload: IFetchProductTour): IFetchProductTourAction => {
  return {
    type: commonActions.FETCH_PRODUCT_TOURS,
    payload
  }
}
export const fetchProductToursSuccess = (payload: any) => {
  return {
    type: commonActions.FETCH_PRODUCT_TOURS_SUCCESS,
    payload
  }
}
export const fetchProductToursFail = (error: string) => {
  return {
    type: commonActions.FETCH_PRODUCT_TOURS_FAIL,
    payload: error
  }
}


export const fetchSectorList = (payload: ITagPayload): IFetchTagsAction => {
  return {
    type: commonActions.FETCH_SECTOR_LIST,
    payload
  }
}
export const fetchSectorListSuccess = (payload: any) => {
  return {
    type: commonActions.FETCH_SECTOR_LIST_SUCCESS,
    payload
  }
}
export const fetchSectorListFail = (error: string) => {
  return {
    type: commonActions.FETCH_SECTOR_LIST_FAIL,
    payload: error
  }
}

export const fetchDegreeList = (payload: ITagPayload): IFetchTagsAction => {
  return {
    type: commonActions.FETCH_DEGREE_LIST,
    payload
  }
}
export const fetchDegreeListSuccess = (payload: any) => {
  return {
    type: commonActions.FETCH_DEGREE_LIST_SUCCESS,
    payload
  }
}
export const fetchDegreeListFail = (error: string) => {
  return {
    type: commonActions.FETCH_DEGREE_LIST_FAIL,
    payload: error
  }
}

export const fetchLanguageJson = (): IFetchLanguagesAction => {
  return {
    type: commonActions.FETCH_LANGUAGE_JSON,
  };
};

export const fetchLanguageJsonSuccess = (): IFetchLanguagesAction => {
  return {
    type: commonActions.FETCH_LANGUAGE_JSON_SUCCESS,
  };
};

export const fetchLanguageJsonFailure = (error: string) => {
  return {
    type: commonActions.FETCH_LANGUAGE_JSON_FAIL,
    payload: error,
  };
};
