import { JsonProperty } from "json-object-mapper";

export class PssciveCourse {

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  private updatedAt: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted: boolean = false;

  @JsonProperty({ name: "country", required: false })
  private country: string = ""; 

  @JsonProperty({ name: "id", required: true })
  private id: string = ""; 

  @JsonProperty({ name: "jobProfiles", required: true })
  private jobProfiles: string[] = []; 

  @JsonProperty({ name: "name", required: true })
  private name: any = {}; 

  @JsonProperty({ name: "sector", required: true })
  private sector: string = ""; 

  @JsonProperty({ name: "education", required: true })
  private education: string = "";

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getCountry(): string {
    return this.country;
  }

  public getId(): string {
    return this.id;
  }

  public getJobProfiles(): string[] {
    return this.jobProfiles;
  }

  public getName() {
    return this.name;
  }

  public getSector(): string {
    return this.sector;
  }

  public getEducation(): string {
    return this.education;
  }

}



